























import { Component, Prop, Vue } from "vue-property-decorator";
import { MDCLinearProgress } from "@material/linear-progress";

@Component
export default class TheMDCLinearProgress extends Vue {
  @Prop() reference!: string;
  @Prop() isIndeterminate?: boolean;
  @Prop() width?: string;
  @Prop() height?: string;
  public linearProgress!: MDCLinearProgress;

  mounted() {
    this.linearProgress = new MDCLinearProgress(
      this.$refs[this.reference as string] as Element
    );
  }
  setProgress(value: number) {
    this.linearProgress.progress = value;
  }
}
