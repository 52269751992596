



























































import { Component, Vue, Prop } from "vue-property-decorator";
import { MDCTabBar } from "@material/tab-bar";
import { TrackingHelpers } from "@/utils/trackingHelpers";

@Component
export default class TheTrackingTitle extends Vue {
  @Prop() reference!: string;
  private tabBar!: MDCTabBar;

  get text() {
    return {
      confirmedShipment: this.$t("tracking.trackingTitle.confirmedShipment"),
      completedShipment: this.$t("tracking.trackingTitle.completedShipment")
    };
  }

  mounted() {
    this.tabBar = new MDCTabBar(this.$refs[this.reference] as HTMLElement);
  }

  get trackingOrderType(): TrackingOrderType {
    return this.$store.getters["tracking/getTrackingOrderType"];
  }

  get getTrackingRecordFilterType() {
    return this.$store.getters["tracking/getTrackingRecordFilterType"];
  }

  async toInProgress() {
    this.$store.commit("showLoading");
    this.$store.commit("tracking/setTrackingRecordFilterType", "inProgress");
    if (this.trackingOrderType == "shipment") {
      await this.$store.dispatch("tracking/retrieveShipmentList");
    } else {
      await this.$store.dispatch("tracking/retrieveExpressIntros");
    }
    this.$store.commit("hideLoading");
  }
  async toDelivered() {
    this.$store.commit("showLoading");
    this.$store.commit("tracking/setTrackingRecordFilterType", "delivered");
    if (this.trackingOrderType == "shipment") {
      await this.$store.dispatch("tracking/retrieveShipmentList");
    } else {
      await this.$store.dispatch("tracking/retrieveExpressIntros");
    }
    this.$store.commit("hideLoading");
  }
}
