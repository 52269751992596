



















import App from "@/App.vue";
import TheTitle from "@/views/shipment/TheTitle.vue";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheTitle,
  },
})
export default class Shipment extends Vue {
  @Ref("theTitle") titleComponent!: TheTitle;
  @Prop() trackingNumber?: string;

  beforeCreate() {
    this.$store.commit("showTopBar");
    this.$store.commit("showNavBar");
    this.$store.commit("showTitle");
  }

  get showTitle() {
    return this.$store.getters.showTitle;
  }

  /**
   * Update shipment title bar progress
   */
  createShipment() {
    this.titleComponent.toCreateShipment();
  }
  proceedShipment() {
    this.titleComponent.toProceed();
  }
  confirmShipment() {
    this.titleComponent.toConfirmation();
  }
  done() {
    this.titleComponent.done();
  }
}
