import { render, staticRenderFns } from "./TheTitle.vue?vue&type=template&id=519f7cb2&scoped=true&"
import script from "./TheTitle.vue?vue&type=script&lang=ts&"
export * from "./TheTitle.vue?vue&type=script&lang=ts&"
import style0 from "./TheTitle.vue?vue&type=style&index=0&id=519f7cb2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "519f7cb2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kenneth/jumppoint/jumppoint-front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('519f7cb2')) {
      api.createRecord('519f7cb2', component.options)
    } else {
      api.reload('519f7cb2', component.options)
    }
    module.hot.accept("./TheTitle.vue?vue&type=template&id=519f7cb2&scoped=true&", function () {
      api.rerender('519f7cb2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/shipment/TheTitle.vue"
export default component.exports