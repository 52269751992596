var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TheMDCSwich__container grid-switch-container" },
    [
      _c("div", { staticClass: "label-item" }, [
        _c(
          "label",
          {
            staticClass: "label__item",
            class: { "color--grey": _vm.isDisabled },
            style: { fontSize: _vm.fontSize },
            attrs: { for: _vm.reference }
          },
          [_vm._v(_vm._s(_vm.label))]
        ),
        _vm.icon
          ? _c(
              "i",
              {
                ref: _vm.icon,
                staticClass: "material-icons label__item mdc-icon-button",
                class: { "color--grey": _vm.isDisabled },
                on: {
                  click: function($event) {
                    _vm.isDisabled ? void 0 : _vm.openDialog()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.icon))]
            )
          : _vm._e()
      ]),
      _c(
        "div",
        {
          ref: _vm.reference,
          staticClass: "mdc-switch switch-item",
          class: { "mid-switch--checked": _vm.isChecked }
        },
        [
          _c("div", { staticClass: "mdc-switch__track" }),
          _c("div", { staticClass: "mdc-switch__thumb-underlay" }, [
            _c("div", { staticClass: "mdc-switch__thumb" }),
            _c("input", {
              staticClass: "mdc-switch__native-control",
              attrs: {
                type: "checkbox",
                id: _vm.reference,
                role: "switch",
                "aria-checked": "false",
                disabled: _vm.isDisabled
              },
              domProps: { checked: _vm.isChecked },
              on: { click: _vm.emitToParent }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }