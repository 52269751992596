export default `<p><strong>Policy Administered by:</strong></p>
<p><span style="font-weight: 400;">Online Shipping Insurance Services, Inc. DBA InsureShip</span></p>
<p><span style="font-weight: 400;">3211 Cahuenga West Suite #200</span></p>
<p><span style="font-weight: 400;">Los Angeles CA 90068</span></p>
<p><span style="font-weight: 400;">License #0G55434</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">jumppoint Insurance is powered by InsureShip and underwritten by The Navigators Group and The Hartford, with an A.M. Best Rating A+ &ldquo;Excellent&rdquo;. jumppoint is not an insurance company; InsureShip handles all jumppoint claims.</span></p>
<p>&nbsp;</p>
<p><strong>This coverage insures the parcel and its contents against damage or loss from any external cause while in transit to, or from, premises utilized by the insured and in care, custody, or control of the carriers.</strong><span style="font-weight: 400;"> Underwriters shall be liable for the invoice value of the property lost, destroyed, or damaged. If there is no invoice, Underwriters shall be liable for the Actual Cash Value (ACV) which is replacement cost less depreciation.</span></p>
<p>&nbsp;</p>
<p><strong>COVERAGE LIMITS</strong></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">This is the maximum value you can insure your item and shipping costs for.</span></p>
<p><strong>$5,000.00 USD</strong><span style="font-weight: 400;"> PER PACKAGE</span></p>
<p><span style="font-weight: 400;">Shipping fees must be included in insured value to receive reimbursement on</span></p>
<p>&nbsp;</p>
<p><strong>ITEMS NOT COVERED BY INSURANCE</strong></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Bulk Products</span><span style="font-weight: 400;">1</span></p>
<p><span style="font-weight: 400;">Cotton</span><span style="font-weight: 400;">2</span></p>
<p><span style="font-weight: 400;">Cash-in-Transit</span><span style="font-weight: 400;">3</span></p>
<p><span style="font-weight: 400;">Computer Chips</span></p>
<p><span style="font-weight: 400;">Cigarettes</span></p>
<p><span style="font-weight: 400;">Eggs</span></p>
<p><span style="font-weight: 400;">Fine Art</span><span style="font-weight: 400;">4</span></p>
<p><span style="font-weight: 400;">Flowers</span></p>
<p><span style="font-weight: 400;">Fresh Foods</span></p>
<p><span style="font-weight: 400;">Laptops</span></p>
<p><span style="font-weight: 400;">Live Animals</span></p>
<p><span style="font-weight: 400;">Mobile Phones</span></p>
<p><span style="font-weight: 400;">Memory Devices</span><span style="font-weight: 400;">5</span></p>
<p><span style="font-weight: 400;">Negotiable Papers</span><span style="font-weight: 400;">6</span></p>
<p><span style="font-weight: 400;">Precious Stones</span><span style="font-weight: 400;">7</span></p>
<p><span style="font-weight: 400;">Precious Metals</span><span style="font-weight: 400;">8</span></p>
<p><span style="font-weight: 400;">Species</span></p>
<p><span style="font-weight: 400;">Tablets</span></p>
<p><span style="font-weight: 400;">Televisions</span></p>
<p><span style="font-weight: 400;">Merchandise shipped in consignment, memorandum, or approval unless shipped in fulfillment of an order or request.</span></p>
<p><span style="font-weight: 400;"><br /></span><strong>FILING TIME FRAMES</strong></p>
<p>&nbsp;</p>
<p><strong>Time to File:</strong><span style="font-weight: 400;"> 90 Calendar Days from Date of Shipment</span></p>
<p><strong>Lost Shipments:</strong><span style="font-weight: 400;"> Wait 20 Calendar Days Domestic; 30 Calendar Days International Shipments</span></p>
<p><strong>Damaged Shipments:</strong><span style="font-weight: 400;"> Can Be Filed Immediately</span></p>
<p>&nbsp;</p>
<p><strong>REQUIRED Documents</strong></p>
<p>&nbsp;</p>
<p><strong>Online Claim Form</strong></p>
<p><strong>Proof of Value</strong><span style="font-weight: 400;"> (Sales/Purchase Invoice)</span></p>
<p><strong>Recipient Statement of Non-Receipt or Damage</strong></p>
<p><span style="font-weight: 400;">(Optional) Pictures of Damage, repair estimate, or salvage value if applicable</span></p>
<p><strong>Failure to hold damaged property until claim is resolved will result in the claim being denied</strong></p>
<p>&nbsp;</p>
<p><strong>CLAIM Processing Info</strong></p>
<p>&nbsp;</p>
<p><strong>Review:</strong><span style="font-weight: 400;"> 5 - 7 Business Days, add additional 3 business days if documents are requested</span></p>
<p><strong>Payment:</strong><span style="font-weight: 400;"> Will be made to the jumppoint account holder</span></p>
<p>&nbsp;</p>
<p><strong>COUNTRIES NOT COVERED BY INSURANCE</strong></p>
<p>&nbsp;</p>
<p><strong>May change based on OFAC9 Embargoes</strong></p>
<p><span style="font-weight: 400;">Afghanistan</span></p>
<p><span style="font-weight: 400;">Angola</span></p>
<p><span style="font-weight: 400;">Bolivia</span></p>
<p><span style="font-weight: 400;">Burma</span></p>
<p><span style="font-weight: 400;">Iran</span></p>
<p><span style="font-weight: 400;">Iraq</span></p>
<p><span style="font-weight: 400;">Congo</span></p>
<p><span style="font-weight: 400;">Ivory Coast</span></p>
<p><span style="font-weight: 400;">Lebanon</span></p>
<p><span style="font-weight: 400;">Liberia</span></p>
<p><span style="font-weight: 400;">Libya</span></p>
<p><span style="font-weight: 400;">Nigeria</span></p>
<p><span style="font-weight: 400;">North Korea</span></p>
<p><span style="font-weight: 400;">Paraguay</span></p>
<p><span style="font-weight: 400;">Somalia</span></p>
<p><span style="font-weight: 400;">Syria</span></p>
<p><span style="font-weight: 400;">Zimbabwe</span></p>
<p><span style="font-weight: 400;">Venezuela</span></p>
<p><span style="font-weight: 400;"><br /></span><strong>EXCLUDED EVENTS/PERILS</strong></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Loss, damage, or non-arrival of any parcel or the contents of said parcel which is:</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Addressed, wrapped, or packed insufficiently, incorrectly or contrary to the packaging requirements of the carrier being used by the Insured</span></p>
<p><span style="font-weight: 400;">Bears a descriptive label or packaging describing the nature of the parcel&rsquo;s contents</span></p>
<p><span style="font-weight: 400;">Any parcel containing personal goods to accommodate an employer or employee</span><span style="font-weight: 400;">1</span></p>
<p><span style="font-weight: 400;">-----------------</span></p>
<p><span style="font-weight: 400;">1</span><span style="font-weight: 400;">Shipping goods not related to business that are personal to the employee or employer.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Loss or Damage caused by</span></p>
<p>&nbsp;</p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Changes in Temperature or Humidity</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Clean Up Costs</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Confiscation or Destruction under Customs Regulations</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Confiscation by any government or public authority</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Delay</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Dishonesty</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Hostile or Warlike Action (during peace and war)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Illegal contraband</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Illegal Transportation of Trade</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Infidelity</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Inherent Vice</span><span style="font-weight: 400;">10</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Latent Defect</span><span style="font-weight: 400;">11</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Loss of Market</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Nuclear Exclusion</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Other Deterioration</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Over Acts by Insured, Associate in Interest, or Insured&rsquo;s Employees, whether occurring during hours of employment or otherwise</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Remote or Consequential Loss (Arising or not arising out of peril insured against)</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Delivering the product to someone who obtains it by trick, false pretense, or other fraudulent schemes</span></li>
</ul>
<p><strong><br /></strong><strong>COVERAGE CONDITIONS</strong></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Against loss or damage caused by or arising out of; a) hostile or warlike action in times of both peace and war, including action hindering, combating, or defending against an actual impending or expected attack; b) any weapon of combat employing atomic fission/fusion or any other radioactive force in times of both war and peace; c) insurrection, rebellion, revolution, civil war, usurped power, or action taken by government authority in hindering, combating or defending against such an occurrence, seizure or destruction under quarantine or customs regulations, confiscation by order of any government or public authority or risks of contraband or illegal transportation of trade. Nuclear Exclusion. Notwithstanding anything herein to the contrary, it is hereby understood and agreed that this policy shall not apply to any loss, damage, or expense due to or resulting from, whether directly or indirectly, nuclear reaction, radiation, or radioactive contamination, no matter what its cause. It is warranted by the insured that all parcels under this coverage will be shipped in accordance with all regulations of the carrier and amendments thereof.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">The deductible, if any, shall be deducted from the amount of the claim or liability limit, whichever is less, on a &ldquo;per parcel&rdquo; basis and borne by the insured. The insured is responsible for (and entitled to) collecting any amount for which the carrier may be liable.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">The insured must wait 20 days for domestic, 30 days for international shipments, to file for non-delivery claims. If damaged, the insured may file a notice of damage with Online Shipping Insurance Services immediately. All claims must be filed within 90 days of shipment to be honored. The insured will submit a completed Online Shipping Insurance Services claim form, copy of the original invoice to the consignee / original invoice from the shipper, whichever is applicable, a signed and dated statement from the consignee / receiving party, proof of damage if applicable and any additional documentation requested to substantiate the loss. Coverage does not include handling fees and insurance fees. All damaged property for which payment (not repair costs), or replacement costs have been made, must, on request, be returned to the Underwriters. Failure to hold the damaged property until the claim is fully resolved will result in the claim&rsquo;s being denied.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">The insured will have one (1) year from the date of the shipment to furnish any required and/or additional documentation requested by Online Shipping Insurance Services in order for the insured to substantiate said claim. Should the insured fail to provide the required and/or additional documentation within one (1) year from the ship date, the insured&rsquo;s claim will be denied. Any covered loss will be promptly paid to the insured or their assignee after notice of loss or damage and the required documentation has been received and accepted by Underwriters in accordance with all terms and conditions of this coverage, unless property is replaced, at the option of the Underwriters, with like kind, function, and quality.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Upon payment for loss or damage, the Underwriters shall be subrogated to all the rights of the insured including whatever money may be recoverable. This payment excludes applicable deductible amounts corresponding to the claim settlement on account of said loss or damage from the carrier, or any of the carrier&rsquo;s employees or agents, or from any other person or corporation whatsoever. The insured specifically covenants and agrees to aid the company in all manners possible to aid in securing reimbursement of said loss or damage. In the event the carrier admits to fraudulent activity from the carrier&rsquo;s employees, the insured will take action against the carrier and not hold Online Shipping Insurance Services responsible for claims associated with said activity.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">No suit, action or proceedings for the recovery of any claim under this coverage shall be sustainable in any court of law or entity unless the respective action is commenced within twelve (12) months after discovery by the insured of the issue giving rise to the claim, provided however, that said time limitation is invalid. In that case any such claim shall be void.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">The insured or the Underwriters may terminate any users from this policy coverage at any time by giving fifteen (15) days written notice thereof, provided that such termination does not impact a shipment already in transit. Mailed or emailed notice of cancellation sent to the insured at the last known address shall be deemed sufficient compliance with the conditions of this clause on the part of the Underwriters.</span></p>
<p>&nbsp;</p>
<p><strong>jumppoint Insurance - Fraud Policy</strong></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">By purchasing jumppoint Insurance, you certify that all information provided is accurate and truthful. Any fraudulent claim will make the shipper and/or consignee liable for prosecution for mail fraud under federal crime code.</span></p>
<p>&nbsp;</p>
<p><strong>DEFINITIONS</strong></p>
<p>&nbsp;</p>
<ol>
<li style="font-weight: 400;"><strong>Bulk Shipments:</strong><span style="font-weight: 400;"> Items shipped without packaging in large quantities loaded directly into a transport&rsquo;s container. *Example: Gravel, Pellets, Seeds, Gasoline.</span></li>
<li style="font-weight: 400;"><strong>Cotton:</strong><span style="font-weight: 400;"> Cotton in its raw/unprocessed form</span></li>
<li style="font-weight: 400;"><strong>Cash-in-Transit:</strong><span style="font-weight: 400;"> Banknotes, coins, credit cards</span></li>
<li style="font-weight: 400;"><strong>Fine Art:</strong><span style="font-weight: 400;"> One-of-a-Kind paintings, sculptures, drawings</span></li>
<li style="font-weight: 400;"><strong>Memory Devices:</strong><span style="font-weight: 400;"> Solid State Hard Drives, Hard Disk Drives, Computer Memory, SD Cards</span></li>
<li style="font-weight: 400;"><strong>Negotiable Papers:</strong><span style="font-weight: 400;"> Checks, Promissory Notes, Bills of Exchange</span></li>
<li style="font-weight: 400;"><strong>Precious Stones:</strong><span style="font-weight: 400;"> Loose diamonds, ruby, sapphire, emeralds, not mounted or fixed in jewelry items</span></li>
<li style="font-weight: 400;"><strong>Precious Metals:</strong><span style="font-weight: 400;"> Valuable metals such as gold, silver, platinum and palladium Exclusion doesn&rsquo;t apply to jewelry</span></li>
<li style="font-weight: 400;"><strong>OFAC:</strong><span style="font-weight: 400;"> Office of Foreign Asset Control: https://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx</span></li>
<li style="font-weight: 400;"><strong>Inherent Vice:</strong><span style="font-weight: 400;"> A quality in property that causes it to deteriorate, damage or destroy itself</span></li>
<li style="font-weight: 400;"><strong>Latent Defect:</strong><span style="font-weight: 400;"> Hidden defect in item and/or workmanship that may cause failure or malfunction which is not discoverable through general inspection</span></li>
</ol>
<p>&nbsp;</p>`;
