var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mdc-card mdc-card--outlined shipment-list-card" },
    [
      _c(
        "div",
        {
          staticClass: "mdc-card__primary-action shipment-list-card-inner",
          attrs: { tabindex: _vm.shipment.orderIndex },
          on: { click: _vm.showShipmentDetail }
        },
        [
          _c("div", { staticClass: "grid-container" }, [
            _c("div", { staticClass: "row__container" }, [
              _c("div", { staticClass: "jumppoint-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.text.mainTrackingNumber) +
                    _vm._s(_vm.text.colon) +
                    _vm._s(_vm.shipment.trackingNumber)
                )
              ]),
              _vm.shipment.items && _vm.shipment.items.length > 0
                ? _c("div", [
                    _c("div", { staticClass: "jumppoint-subtitle" }, [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.text.itemTrackingNumber) +
                            _vm._s(_vm.text.colon)
                        )
                      ]),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.displayItemTrackingNumbers(_vm.shipment.items)
                          )
                        )
                      ])
                    ])
                  ])
                : _vm._e()
            ]),
            _c(
              "div",
              {
                staticClass: "row_container inner-grid-state-container",
                staticStyle: { width: "95%", margin: "auto" }
              },
              [
                _c("div", { staticClass: "left-item" }, [
                  _c("div", { staticClass: "country pre-wrap-text" }, [
                    _vm._v(_vm._s(_vm.getShipmentOriginDistrict))
                  ]),
                  _c("div", { staticClass: "contactName" }, [
                    _vm._v(_vm._s(_vm.shipment.origin.contactName))
                  ]),
                  _c("div", { staticClass: "contactPhone" }, [
                    _vm._v(_vm._s(_vm.shipment.origin.contactPhone))
                  ])
                ]),
                _c("div", { staticClass: "middle-item" }, [
                  _c("div", { staticClass: "shipment-status" }, [
                    _vm._v(_vm._s(_vm.getShipmentListItemState))
                  ])
                ]),
                _c("div", { staticClass: "right-item" }, [
                  _c("div", { staticClass: "country  pre-wrap-text" }, [
                    _vm._v(_vm._s(_vm.getShipmentDestinationDistrict))
                  ]),
                  _vm.shipment.isSelfPickUp
                    ? _c("div", { staticClass: "pickUpStore" }, [
                        _vm._v(_vm._s(_vm.$t("pickUpStore.pickUpStore")))
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "contactName" }, [
                    _vm._v(_vm._s(_vm.shipment.destination.contactName))
                  ]),
                  _c("div", { staticClass: "contactPhone" }, [
                    _vm._v(_vm._s(_vm.shipment.destination.contactPhone))
                  ])
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "row__container inner-grid-button-container" },
              [
                _c(
                  "div",
                  {
                    staticClass: "mdc-card__action",
                    staticStyle: { "margin-left": "0.5rem" }
                  },
                  [
                    _c("TheMDCButtonShapedRaised", {
                      ref: "exportLabel",
                      staticClass: "mdc-card__action-button",
                      attrs: {
                        label: _vm.text.exportLabel,
                        reference: "exportLabel",
                        event: "exportLabel",
                        trailIcon: "file_copy",
                        isDisabled:
                          _vm.trackingOrderType === "shipment" &&
                          !_vm.shipment.labelUrl,
                        fontSize: "min(3vw, 0.8rem)",
                        height: "min(4vh, 2rem)"
                      },
                      on: { exportLabel: _vm.exportLabel }
                    })
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "row__container" }, [
              _vm.isShipment && _vm.shipment.labelIssueDateTime
                ? _c("p", { staticClass: "footer" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.text.labelIssueDate) +
                        _vm._s(_vm.text.colon) +
                        _vm._s(
                          new Date(
                            _vm.shipment.labelIssueDateTime
                          ).toLocaleDateString() +
                            " " +
                            new Date(
                              _vm.shipment.labelIssueDateTime
                            ).toLocaleTimeString()
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm.isExpress
                ? _c("p", { staticClass: "footer" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.text.expressCreatedAt) +
                        _vm._s(_vm.text.colon) +
                        _vm._s(
                          new Date(
                            _vm.shipment.createdAt
                          ).toLocaleDateString() +
                            " " +
                            new Date(
                              _vm.shipment.createdAt
                            ).toLocaleTimeString()
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }