























































































































































































































































































































































































































































import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator';

import App from '@/App.vue';

// mdc component
import TheMDCButtonOutlined from '@/components/mdcComponents/buttons/TheMDCButtonOutlined.vue';
import TheMDCButtonShapedRaised from '@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue';
import TheMDCButtonShaped from '@/components/mdcComponents/buttons/TheMDCButtonShaped.vue';
import TheMDCSwitch from '@/components/mdcComponents/switches/TheMDCSwitch.vue';
import TheMDCIconbutton from '@/components/mdcComponents/buttons/TheMDCIconButton.vue';
import TheMDCRadioButton from '@/components/mdcComponents/radio/TheMDCRadioButton.vue';
import TheMDCTextFieldOutlined from '@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue';
import TheMDCDatePicker from '@/components/mdcComponents/pickers/TheMDCDatePicker.vue';
import TheMDCSelectOutlined from '@/components/mdcComponents/selects/TheMDCSelectOutlined.vue';
import * as TranslationHelpers from '@/utils/translationHelpers';

// specified prompt conmtent
import INSURANCE_HTML from '@/assets/INSURANCE_HTML';
import { ShipmentHelpers } from '@/utils/shipmentHelpers';
// import { Template } from "webpack";

//style import
import theme from '@/assets/scss/_theme.scss';

@Component({
  components: {
    TheMDCButtonOutlined,
    TheMDCButtonShapedRaised,
    TheMDCButtonShaped,
    TheMDCSwitch,
    TheMDCIconbutton,
    TheMDCTextFieldOutlined,
    TheMDCRadioButton,
    TheMDCDatePicker,
    TheMDCSelectOutlined,
  },
})
export default class ShipmentForm extends Vue implements VuePromptCaller {
  // Visitor pattern: See module.d.ts
  public on(event: string, data?: any, execution?: CallBack<void> | null) {
    if (event === 'accept') {
      if (execution) {
        execution(event, data);
      }
    }
  }

  // get a reference to the App Component Instance
  // this.$app
  private ROOT = this.$root.$children[0] as App;

  private pickUpDateMenuList: MenuItem[] = [];
  private pickUpDate = '';
  private deliveryDateMenuList: MenuItem[] = [];
  //private toPickUpStore = false;

  // private pickUpStore: PickUpStore = {} as PickUpStore;

  @Ref('orderNotes') orderNoteComponent!: TheMDCTextFieldOutlined;
  @Ref('paymentMethodPaidByMerchant') paidByMerchantRadioComponent!: TheMDCRadioButton;
  @Ref('paymentMethodPaidByRecipient') paidByRecipientRadioComponent!: TheMDCRadioButton;
  @Ref('paymentMethodPaidByMerchantCashOnDelivery') paidByMerchantCashOnDeliveryRadioComponent!: TheMDCRadioButton;
  @Ref('paymentMethodCashOnDelivery') cashOnDeliveryRadioComponent!: TheMDCRadioButton;
  @Ref('cashOnDeliveryAmount') cashOnDeliveryAmountComponent!: TheMDCTextFieldOutlined;
  @Ref('deliveryDate') deliveryDateComponent!: TheMDCSelectOutlined; //state in api /shipment/create
  @Ref('pickUpDate') pickUpDateComponent!: TheMDCSelectOutlined; //state in api /shipment/create\

  // @Ref("pickUpStoreDistrict") pickUpStoreDistrictComponent!: TheMDCSelectOutlined; //state in api /shipment/create\
  // @Ref("pickUpStoreArea") pickUpStoreAreaComponent!: TheMDCSelectOutlined; //state in api /shipment/create\
  // @Ref("pickUpStore") pickUpStoreComponent!: TheMDCSelectOutlined; //state in api /shipment/create\

  // deliveryDateMin= DateFormatHelpers.formattedJSDateStringFromDate(DateFormatHelpers.getDateObject(new Date(),'day',1));
  // deliveryDateMax= DateFormatHelpers.formattedJSDateStringFromDate(DateFormatHelpers.getDateObject(DateFormatHelpers.getDateObject(new Date(),'day',1), 'month', 1));
  get theme() {
    return theme;
  }

  get text() {
    return {
      // label
      addSender: this.$t('shipment.shipmentForm.shipmentForm.addSender'),
      addReceiver: this.$t('shipment.shipmentForm.shipmentForm.addReceiver'),
      addItem: this.$t('shipment.shipmentForm.shipmentForm.addItem'),
      shippingAddresses: this.$t('shipment.shipmentForm.shipmentForm.shippingAddresses'),
      deliveryDetail: this.$t('shipment.shipmentForm.shipmentForm.deliveryDetail'),
      isSubprimeTitle: this.$t('shipment.shipmentForm.shipmentForm.isSubprimeTitle'),
      orderNotesPlaceholder: this.$t('shipment.shipmentForm.shipmentForm.orderNotesPlaceholder'),
      paidByMerchant: this.$t('shipment.paymentMethod.paidByMerchant'),
      paidByRecipient: this.$t('shipment.paymentMethod.paidByRecipient'),
      cashOnDelivery: this.$t('shipment.paymentMethod.cashOnDelivery'),
      paidByMerchantCashOnDelivery: this.$t('shipment.paymentMethod.paidByMerchantCashOnDelivery'),
      paymentMethod: this.$t('shipment.paymentMethod.paymentMethod'),
      cashOnDeliveryAmountPlaceholder: this.$t('shipment.shipmentForm.shipmentForm.cashOnDeliveryAmountPlaceholder'),
      cashOnDeliveryAmountReminder: this.$t('shipment.shipmentForm.shipmentForm.cashOnDeliveryAmountReminder'),

      // button
      proceed: this.$t('shipment.shipmentForm.shipmentForm.proceed'),
      reset: this.$t('shipment.shipmentForm.shipmentForm.reset'),
      isInsured: this.$t('shipment.shipmentForm.shipmentForm.isInsured'),
      taxDutyPaidBy: this.$t('shipment.shipmentForm.shipmentForm.taxDutyPaidBy'),
      isSubprime: this.$t('shipment.shipmentForm.shipmentForm.isSubprime'),
      isDocument: this.$t('shipment.shipmentForm.shipmentForm.isDocument'),

      // prompts
      delItemPromptMsg: this.$t('shipment.shipmentForm.shipmentForm.delItemPromptMsg'),

      addReceiverAddress: this.$t('shipment.shipmentForm.shipmentForm.addReceiverAddress'),
      modifyItemTitle: this.$t('shipment.shipmentForm.shipmentForm.modifyItemTitle'),

      insuranceInfo: {
        title: this.$t('dialogs.insurance.title'),
        message: INSURANCE_HTML,
        button: this.$t('dialogs.close'),
      },
      taxPaidByInfo: {
        title: this.$t('shipment.shipmentForm.shipmentForm.taxPaidByInfo.title'),
        message: this.$t('shipment.shipmentForm.shipmentForm.taxPaidByInfo.message'),
        button: this.$t('dialogs.close'),
      },

      // errors
      quoteRateError: {
        title: this.$t('shipment.shipmentForm.shipmentForm.quoteRateError.title'),
        message: this.$t('shipment.shipmentForm.shipmentForm.quoteRateError.message'),
      },
    };
  }

  created() {
    this.updatePickUpDateMenuList(this.getPickUpDate);
    this.updateDeliveryDateMenuList();
    //if(this.pickUpStoreId || this.$store.getters["shipment/getToPickUpStore"]){
    //this.toPickUpStore = true;
    //}
  }
  mounted() {
    this.$store.commit('showNavBar');
    this.$store.commit('showTopBar');
    this.$store.commit('showTitle');
    this.$emit('shipment');
    this.$nextTick(function() {
      this.fillingShipmentFormInput();
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit('hideLoading');
    });

    // this.expressOrderItemDisplay();
  }

  // filling shipment form input when duplicate shipment or get data from session data
  private fillingShipmentFormInput() {
    this.checkPaymentMethodAvailability();
    const orderNotes = this.$store.getters['shipment/getOrderNotes'];
    const cashOnDeliveryAmount = this.$store.getters['shipment/getCashOnDeliveryAmount'];

    this.orderNoteComponent.setValue(orderNotes ? orderNotes : '');
    const paymentMethod = this.$store.getters['shipment/getPaymentMethod'];

    switch (paymentMethod) {
      case 'PAID_BY_RECEIVER':
        this.paidByRecipientRadioComponent.setChecked(true);
        break;
      case 'CASH_ON_DELIVERY':
        this.cashOnDeliveryRadioComponent.setChecked(true);
        this.cashOnDeliveryAmountComponent.setValue(cashOnDeliveryAmount);
        break;
      case 'PAID_BY_MERCHANT_COD':
        this.paidByMerchantCashOnDeliveryRadioComponent.setChecked(true);
        this.cashOnDeliveryAmountComponent.setValue(cashOnDeliveryAmount);
        break;
      default:
        this.paidByMerchantRadioComponent.setChecked(true);
    }
    // if(this.isDocument){
    if (this.deliveryDate && this.isDocument) {
      this.deliveryDateComponent.setSelectedValue(this.deliveryDate);
      // this.updatePickUpDateMenuList(this.deliveryDate);
      this.pickUpDate = this.getPickUpDate;
      if (this.pickUpDate) {
        this.pickUpDateComponent.setSelectedValue(this.pickUpDate);
      }
    } else {
      this.resetDeliveryDate();
    }
  }

  private checkPaymentMethodAvailability(){
    let paymentMethod = this.$store.getters['shipment/getPaymentMethod'];
    if(this.pickUpStoreCannotCollectCash && ['PAID_BY_RECEIVER', 'CASH_ON_DELIVERY', 'PAID_BY_MERCHANT_COD'].includes(paymentMethod)){
      paymentMethod = 'PAID_BY_MERCHANT';
      this.$store.commit('shipment/setPaymentMethod', paymentMethod);
      this.setCashOnDeliveryAmount(0);

      this.showPaymentMethodNotAvailableDialog();
    }
  }
    get pickUpStoreCannotCollectCash() {
    return (
      !this.isDocument && this.pickUpStore.id && this.pickUpStoreId && this.pickUpStore.providingServices && !this.pickUpStore?.providingServices?.cashReceipt
    );
  }


  get insurancePromptData(): PromptData {
    return {
      title: this.text.insuranceInfo.title as string,
      HTMLMsg: this.text.insuranceInfo.message as string,
      closeBlt: this.text.insuranceInfo.button as string,
    };
  }
  get taxPromptData(): PromptData {
    return {
      title: this.text.taxPaidByInfo.title as string,
      msg: this.text.taxPaidByInfo.message as string,
      closeBlt: this.text.insuranceInfo.button as string,
    };
  }
  get subprimePromptData(): PromptData {
    const msg = [
      this.$t('shipment.shipmentForm.shipmentForm.normalExpressOrderPrice'),
      ' $ ',
      process.env.VUE_APP_NON_SAME_DAY_DELIVERY_PRICE,
      ' ; ',
      this.$t('shipment.shipmentForm.shipmentForm.sameDayExpressOrderPrice'),
      ' $ ',
      process.env.VUE_APP_SAME_DAY_DELIVERY_PRICE,
    ].join('');
    return {
      title: this.$t('dialogs.reminder') as string,
      msg: msg as string,
      closeBlt: this.$t('dialogs.close') as string,
    };
  }
  get isDocumentPromptData(): PromptData {
    return {
      title: this.$t('dialogs.reminder') as string,
      msg: ((this.$t('shipment.shipmentForm.shipmentForm.isDocumentMessage') as string) +
        '\n\n' +
        this.$t('shipment.shipmentForm.shipmentForm.deliveryDateRules')) as string,
      closeBlt: this.$t('dialogs.close') as string,
    };
  }

  get cashOnDeliveryAmountPromptData(): PromptData {
    return {
      title: this.$t('dialogs.reminder') as string,
      msg: this.text.cashOnDeliveryAmountReminder as string,
      closeBlt: this.$t('dialogs.close') as string,
    };
  }

  get taxPaidBy() {
    return this.$store.getters['shipment/getShipmentTaxesDutiesPaidBy'] == 'Sender';
  }
  get isInsured() {
    return this.$store.getters['shipment/getShipmentIsInsured'];
  }
  get isSubprime() {
    return this.$store.getters['shipment/getShipmentIsSubprime'];
  }
  get shipmentType(): string {
    return this.isSubprime ? 'expressOrder' : 'otherCourier';
  }
  get isDocument() {
    return this.$store.getters['shipment/getShipmentIsDocument'];
  }

  get thirdPartyItemLimitReached(){
    return this.pickUpStore?.isThirdParty && this.expressOrderItems && this.expressOrderItems.length > 0;
  }
  get canProceed() {
    //regular easyship shipment
    if (!this.isSubprime) {
      return this.hasSender && (this.hasReceiver || this.pickUpStoreId) && this.hasItem;
      // is Subprime
    } else if (this.isSubprime && !this.isDocument) {
      return (
        this.hasSender &&
        (this.hasReceiver || this.pickUpStoreId) &&
        this.hasItem &&
        this.trackingNumber &&
        ((this.hasCashOnDelivery && this.validateCashOnDeliveryAmount) || !this.hasCashOnDelivery)
      );
      //is Document
    } else {
      return (
        this.hasSender &&
        (this.hasReceiver || this.pickUpStoreId) &&
        this.hasItem &&
        !!this.deliveryDate &&
        this.trackingNumber &&
        ((this.hasCashOnDelivery && this.validateCashOnDeliveryAmount) || !this.hasCashOnDelivery)
      );
    }
  }
  get hasSender() {
    return this.$store.getters['shipment/hasShipmentSenderAddress'];
  }
  get hasReceiver() {
    return this.$store.getters['shipment/hasShipmentReceiverAddress'];
  }
  get hasItem() {
    if (this.isSubprime) {
      return this.$store.getters['shipment/getExpressOrderItems'].length != 0;
    } else {
      return this.$store.getters['shipment/hasShipmentItem'];
    }
  }

  get hasCashOnDelivery() {
    return ['CASH_ON_DELIVERY', 'PAID_BY_MERCHANT_COD'].find(paymentMethod => paymentMethod === this.paymentMethod);
  }

  get validateCashOnDeliveryAmount() {
    return (
      typeof this.cashOnDeliveryAmount != 'undefined' &&
      !isNaN(this.cashOnDeliveryAmount) &&
      Number(this.cashOnDeliveryAmount) > 0
    );
  }

  get trackingNumber() {
    return this.$store.getters['shipment/getTrackingNumber'];
  }
  get senderInfo(): string {
    const sender: Address = this.$store.getters['shipment/getShipmentSenderAddress'];
    return (
      sender.contactName + ' ' + sender.contactPhone + ' ' + (sender.countryCodeAlpha2 ? sender.countryCodeAlpha2 : '')
    );
  }
  get receiverInfo(): string {
    const receiver: Address = this.$store.getters['shipment/getShipmentReceiverAddress'];
    return (
      receiver.contactName +
      ' ' +
      receiver.contactPhone +
      ' ' +
      (receiver.countryCodeAlpha2 ? receiver.countryCodeAlpha2 : '')
    );
  }

  get toPickUpStore(): boolean {
    return this.$store.getters['shipment/getToPickUpStore'];
  }

  get pickUpStoreContactInfo(): string {
    return [
      this.$store.getters['shipment/getPickUpName'] || '',
      this.$store.getters['shipment/getPickUpPhone'] || '',
    ].join(' ');
  }

  get getPickUpDate(): string {
    return this.$store.getters['shipment/getPickUpDate'];
  }

  get deliveryDate(): string {
    return this.$store.getters['shipment/getDeliveryDate'];
  }

  get availableDeliveryDates(): string[] {
    return this.$store.getters['date/getAvailableDeliveryDates'];
  }

  get expressOrderItems(): ExpressOrderItem[] {
    return this.$store.getters['shipment/getExpressOrderItems'];
  }
  private expressOrderItemDisplay(expressOrderItem: ExpressOrderItem) {
    let display = '';
    if (expressOrderItem) {
      if (expressOrderItem.categoryOptionId) {
        const category: Option = this.$store.getters[
          ('itemOption/expressOrder/' +
            (this.isDocument ? 'getSameDayCategoriesById' : 'getNonSameDayCategoriesById')) as string
        ](expressOrderItem.categoryOptionId);
        display = !category
          ? [
              this.$t('shipment.shipmentForm.itemForm.other') as string,
              this.$t('general.colon') as string,
              expressOrderItem.category,
            ].join('')
          : ((this.$i18n.locale === 'cant' ? category.descriptionChi : category.descriptionEn) as string);
      } else if (expressOrderItem.category) {
        display = expressOrderItem.category;
      }
    }
    return display;
  }

  private getItemInfo(itemId: number) {
    return this.$store.getters['shipment/getShipmentItems'].find((item: Item) => item.itemId == itemId).description;
  }

  get items(): Item[] {
    return this.$store.getters['shipment/getShipmentItems'];
  }

  get orderNotes(): string {
    return this.$store.getters['shipment/getOrderNotes'] ? this.$store.getters['shipment/getOrderNotes'] : '-';
  }

  get paymentMethod(): PaymentMethod {
    return this.$store.getters['shipment/getPaymentMethod'];
  }
  get cashOnDeliveryAmount(): number {
    return this.paymentMethod === 'CASH_ON_DELIVERY' || this.paymentMethod === 'PAID_BY_MERCHANT_COD'
      ? this.$store.getters['shipment/getCashOnDeliveryAmount']
      : 0;
  }

  get senderAddressMenu(): Array<MenuItem> {
    if (this.isSubprime) {
      return this.expressOrderAddressMenu(
        this.$store.getters['historialInput/expressOrder/getUserSavedSenderAddresses'],
      );
    } else {
      return this.addressMenu(this.$store.getters['historialInput/otherCourier/getUserSavedSenderAddresses']);
    }
  }

  get receiverAddressMenu(): Array<MenuItem> {
    if (this.isSubprime) {
      return this.expressOrderAddressMenu(
        this.$store.getters['historialInput/expressOrder/getUserSavedReceiverAddresses'],
      );
    } else {
      return this.addressMenu(this.$store.getters['historialInput/otherCourier/getUserSavedReceiverAddresses']);
    }
  }

  private addressMenu(addresses: Address[]): Array<MenuItem> {
    const addressMenu: MenuItem[] = [];
    addresses.forEach(address => {
      addressMenu.push({
        key: address.id as string,
        value: address.id,
        expression: address.addressLine1 + address.addressLine2,
        additionData: address,
      });
    });
    return addressMenu;
  }

  private expressOrderAddressMenu(addresses: ExpressOrderAddress[]): Array<MenuItem> {
    const addressMenu: MenuItem[] = [];
    addresses.forEach(address => {
      addressMenu.push({
        key: address.id as string,
        value: address.id,
        expression: address.fullAddress,
        additionData: address,
      });
    });
    return addressMenu;
  }

  get itemMenu(): MenuItem[] {
    // vuex store item
    const savedItems: Item[] = this.$store.getters['historialInput/otherCourier/getUserSavedItems'];
    // => menuItem[]
    const itemMenu: MenuItem[] = [];
    savedItems.forEach((item, index) => {
      itemMenu.push({
        key: index,
        value: index,
        expression: item.description,
        additionData: item,
      });
    });
    return itemMenu;
  }

  private async goToQrCode() {
    await this.$router.push({ name: 'qrCodeScanner' }).catch(err => {
      this.$store.commit('hideLoading');
    });
  }

  get expressOrderItemMenu(): MenuItem[] {
    const savedItems: ExpressOrderItem[] = this.$store.getters[
      'historialInput/expressOrder/' + (this.isDocument ? 'getUserSavedSameDayItems' : 'getUserSavedItems')
    ];
    // => menuItem[]
    const expressOrderItemMenu: MenuItem[] = [];
    savedItems.forEach((item, index) => {
      expressOrderItemMenu.push({
        key: index,
        value: index,
        expression:
          (this.$i18n.locale === 'cant' ? item.categoryDescriptionChi : item.categoryDescriptionEn) || item.category,
        additionData: item,
      });
    });
    return expressOrderItemMenu;
  }

  private reset() {
    this.$store.commit('shipment/resetShipment');
    // coz textfield store value inside component, so it need to set value after reset state
    // this.deliveryDateComponent.setSelectedValue("");
    // this.pickUpDateComponent.setSelectedValue("");
    // this.pickUpDateMenuList = [];
    this.resetDeliveryDate();
  }

  private removePickUpOrRecipientAddress() {
    if (this.toPickUpStore) {
      this.$store.commit('shipment/saveShipmentReceiverAddress', null);
    } else {
      this.$store.commit('shipment/setPickUpStoreId', '');
    }
  }
  private async proceed() {
    if (this.isSubprime) {
      this.expressOrderCheckout();
    } else {
      await this.quoteRate();
    }
  }

  private async expressOrderCheckout() {
    this.removePickUpOrRecipientAddress();
    //  if shipment is same day delivery, then need to validate delivery date before continue
    await this.$router.push({ name: 'ConfirmAndPay' });
  }

  private async quoteRate() {
    this.$store.commit('showLoading');
    const quoteRate = await this.$store.dispatch('shipment/quoteRate');
    if (quoteRate) {
      await this.$router.push({ name: 'ConfirmAndProceed' });
    } else if (quoteRate == false) {
      this.showQuoteRateError();
    }
    this.$store.commit('hideLoading');
  }

  private setOrderNotes(orderNotes: string) {
    this.$store.commit('shipment/setOrderNotes', orderNotes);
  }

  private setPaymentMethod() {
    const paymentMethod = (document.querySelector('input[name="paymentMethod"]:checked') as HTMLInputElement).value;
    this.$store.commit('shipment/setPaymentMethod', paymentMethod);
    if (paymentMethod === 'CASH_ON_DELIVERY' || paymentMethod === 'PAID_BY_MERCHANT_COD') {
      this.ROOT.showPrompt(this.cashOnDeliveryAmountPromptData);
    }
  }

  private setCashOnDeliveryAmount(cashOnDeliveryAmount: number) {
    if (!isNaN(cashOnDeliveryAmount) && Number(cashOnDeliveryAmount) > 0) {
      this.$store.commit('shipment/setCashOnDeliveryAmount', Number(cashOnDeliveryAmount));
    } else {
      this.$store.commit('shipment/setCashOnDeliveryAmount', null);
    }
  }

  private setInsured(isInsured: boolean) {
    this.$store.commit('shipment/setIsInsured', isInsured);
  }

  private setTaxPaidBy(payBySender: boolean) {
    this.$store.commit('shipment/setTaxesDutiesPaidBy', payBySender ? 'Sender' : 'Receiver');
  }

  private setPickUpDate(pickUpDate: string) {
    if (this.pickUpDateComponent.getSelectedValue() != pickUpDate) {
      this.pickUpDateComponent.setSelectedValue(pickUpDate);
    }
    this.$store.commit('shipment/setPickUpDate', pickUpDate);
  }

  private setDeliveryDate(deliveryDate: string) {
    if (deliveryDate !== this.deliveryDate) {
      this.updatePickUpDateMenuList(deliveryDate);
      if (this.pickUpDateMenuList.find(menuList => menuList.value == this.pickUpDate) === undefined) {
        this.resetPickUpDate();
      }
    }
    this.$store.commit('shipment/setDeliveryDate', deliveryDate);

    // if(!ShipmentHelpers.getAvailablePickUpDates(deliveryDate).includes(deliveryDate))
  }

  private updateDeliveryDateMenuList() {
    this.deliveryDateMenuList = [];
    const avaliableDeliveryDates = this.$store.getters['date/getAvailableDeliveryDates'];

    for (let i = 0; i < avaliableDeliveryDates.length; i++) {
      this.deliveryDateMenuList.push({
        key: i,
        value: avaliableDeliveryDates[i],
        expression:
          avaliableDeliveryDates[i] +
          this.$t('general.leftBracket') +
          TranslationHelpers.weekDayTranslate(avaliableDeliveryDates[i]) +
          this.$t('general.rightBracket'),
      });
    }
  }

  private updatePickUpDateMenuList(deliveryDate: string) {
    const availablePickUpDates = ShipmentHelpers.getAvailablePickUpDates(deliveryDate);
    this.pickUpDateMenuList = [];
    if (deliveryDate) {
      for (let i = 0; i < availablePickUpDates.length; i++) {
        this.pickUpDateMenuList.push({
          key: i,
          value: availablePickUpDates[i],
          expression:
            availablePickUpDates[i] +
            this.$t('general.leftBracket') +
            TranslationHelpers.weekDayTranslate(availablePickUpDates[i]) +
            this.$t('general.rightBracket'),
        });
      }
    }
  }

  get pickUpStoreDistricts() {
    return this.$store.getters['pickUpStore/getDistricts'];
  }

  get pickUpStoreId() {
    return this.$store.getters['shipment/getPickUpStoreId'];
  }

  get pickUpStore(): PickUpStore {
    return this.$store.getters['pickUpStore/getPickUpStoreById'](this.pickUpStoreId);
  }
  private resetDeliveryDate() {
    const defaultDeliveryDate = '';
    this.$store.commit('shipment/setDeliveryDate', defaultDeliveryDate);
    this.deliveryDateComponent.setSelectedValue(defaultDeliveryDate);
    this.resetPickUpDate();
    this.updatePickUpDateMenuList(defaultDeliveryDate);
  }
  private resetPickUpDate() {
    this.$store.commit('shipment/setPickUpDate', '');
    this.pickUpDateComponent.setSelectedValue('');
  }

  /**
   * CAUTION!! Complicated
   *
   * These functions are responsible for selecting the correct target ( which item/address object ) and action ( create / modify )
   * for addressForm and itemForm page
   */
  private setSubprime(isSubprime: boolean) {
    this.$store.commit('shipment/resetShipment');
    this.$store.commit('shipment/setIsSubprime', isSubprime);
    if (this.isSubprime) {
      this.showSubprimePrompt();
    }
    this.resetTrackingNumber();
  }

  private setIsDocument(isDocument: boolean) {
    this.$store.commit('shipment/setIsDocument', isDocument);
    this.resetExpressOrderItems();
    this.resetPickUpStore();
    if (this.isDocument) {
      this.showIsDocumentPrompt();
    }
    this.resetTrackingNumber();
    this.checkPaymentMethodAvailability();
  }

  private resetExpressOrderItems() {
    this.$store.commit('shipment/setExpressOrderItems', []);
  }

  private resetPickUpStore() {
    this.$store.commit('shipment/setToPickUpStore', false);
    this.$store.commit('shipment/setPickUpStoreId', '');
    this.$store.commit('shipment/setPickUpName', '');
    this.$store.commit('shipment/setPickUpPhone', '');
  }

  private resetReceiverAddress() {
    this.$store.commit('shipment/saveShipmentReceiverAddress', null);
  }

  private resetTrackingNumber() {
    this.$store.commit('shipment/setTrackingNumber', null);
  }

  private showSubprimePrompt() {
    this.ROOT.showPrompt(this.subprimePromptData);
  }

  private showIsDocumentPrompt() {
    this.ROOT.showPrompt(this.isDocumentPromptData);
  }

  private showAddShipmentDataPrompt(action: 'CreateItem' | 'ModifyItem', target: 'Sender' | 'Receiver' | string) {
    if (target === 'Sender' || target === 'Receiver') {
      this.showAddressPrompt(action, target);
    } else if (this.isSubprime) {
      this.showExpressOrderItem('ModifyItem', target);
    } else {
      //this.showItemPrompt(action, target);
    }
  }

  private showAddressPrompt(action: 'CreateItem' | 'ModifyItem', target: 'Sender' | 'Receiver') {
    const isSender = target === 'Sender';
    const menuList = isSender ? this.senderAddressMenu : this.receiverAddressMenu;
    const title = isSender
      ? (this.$t('shipment.shipmentForm.shipmentForm.addSenderAddress') as string)
      : (this.$t('shipment.shipmentForm.shipmentForm.addReceiverAddress') as string);
    const subtitle = isSender
      ? (this.$t('dialogs.addShipmentDataPrompt.selectASavedAddress') as string)
      : (this.$t('dialogs.addShipmentDataPrompt.selectASavedAddress') as string);
    const callBack = ((action, target) => {
      return (_: 'accept', addressPromptReturn: string | '-1') => {
        let templateId = addressPromptReturn; // templateId = addressId or 0
        if (addressPromptReturn === '-1') {
          templateId = '0';
        }
        this.toAddAddress(action, target, templateId);
      };
    })(action, target);
    this.ROOT.showAddShipmentDataPrompt({
      action: action,
      menuList: menuList,
      title: title,
      subtitle: subtitle,
      listener: this,
      execution: callBack,
    });
  }

  private showItemPrompt(action: 'CreateItem' | 'ModifyItem', itemId: number) {
    // serve as a important role to be the middle ware of AddShipmentDataPrompt and AddAddressForm
    this.ROOT.showAddShipmentDataPrompt({
      action: action,
      menuList: this.itemMenu,
      title: this.$t('shipment.shipmentForm.shipmentForm.newItemTitle') as string,
      subtitle: this.$t('dialogs.addShipmentDataPrompt.selectASavedItem') as string,
      listener: this,
      execution: ((action: 'CreateItem' | 'ModifyItem', itemId: number) => {
        return (_: any, itemPromptReturn: number | '-1') => {
          let templateItemId = itemPromptReturn; // templateItemId = savedItemId or -1
          if (itemPromptReturn === '-1') {
            // user clicked create new item || user clicked modify item
            templateItemId = -1;
          }
          this.toAddItem(action, itemId, templateItemId as number);
        };
      })(action, itemId),
    });
  }

  private showExpressOrderItem(action: 'CreateItem' | 'ModifyItem', itemId: string) {
    // serve as a important role to be the middle ware of AddShipmentDataPrompt and AddAddressForm
    this.ROOT.showAddShipmentDataPrompt({
      action: action,
      menuList: this.expressOrderItemMenu,
      title: this.$t('shipment.shipmentForm.shipmentForm.newItemTitle') as string,
      subtitle: this.$t('dialogs.addShipmentDataPrompt.selectASavedItem') as string,
      listener: this,
      execution: ((action: 'CreateItem' | 'ModifyItem', itemId: string) => {
        return (_: any, itemPromptReturn: number | '-1') => {
          const templateItemId = itemPromptReturn; // templateItemId = savedItemId or -1
          //if (itemPromptReturn === '-1') {
          //  // user clicked create new item || user clicked modify item
          //  templateItemId = -1;
          //}
          this.toAddExpressOrderItem(itemId, templateItemId as string);
        };
      })(action, itemId),
    });
  }
  private showDelItemPromp(itemId: number): void {
    this.ROOT.showPrompt({
      msg: this.text.delItemPromptMsg as string,
      acceptBlt: this.$t('dialogs.confirm') as string,
      closeBlt: this.$t('dialogs.cancel') as string,
      listener: this,
      execution: ((itemId: number) => {
        // delete item by itemId function
        return () => {
          this.$store.commit('shipment/removeShipmentItem', itemId);
        };
      })(itemId),
    });
  }

  private showDelExpressOrderItemPromp(trackingNumber: string) {
    this.ROOT.showPrompt({
      msg: this.text.delItemPromptMsg as string,
      acceptBlt: this.$t('dialogs.confirm') as string,
      closeBlt: this.$t('dialogs.cancel') as string,
      listener: this,
      execution: (() => {
        // delete item by itemId function
        return () => {
          const orders = this.$store.getters['shipment/getExpressOrderItems'].filter(
            (item: ExpressOrderItem) => item.trackingNumber != trackingNumber,
          );
          this.$store.commit('shipment/setExpressOrderItems', orders);
        };
      })(),
    });
  }
  private showQuoteRateError(): void {
    this.ROOT.showPrompt({
      title: this.text.quoteRateError.title as string,
      msg: this.text.quoteRateError.message as string,
      closeBlt: this.$t('dialogs.close') as string,
    });
  }

    private showPaymentMethodNotAvailableDialog(){
     this.ROOT.showPrompt({
      title: this.$t('dialogs.pickUpStoreNotProvidingCashCollection.title') as string,
      msg: this.$t('dialogs.pickUpStoreNotProvidingCashCollection.message') as string,
      closeBlt: this.$t('dialogs.close') as string,
    });
  }

  async toAddAddress(
    // route to add address page
    action: 'CreateItem' | 'ModifyItem',
    identity: 'Sender' | 'Receiver',
    templateId: string | '0',
  ) {
    if (this.isSubprime) {
      this.toAddExpressOrderAddress(action, identity, templateId);
    } else {
      await this.$router.push({
        //defetmine order type to different page
        name: 'Address',
        params: { action: action, identity: identity, templateId: templateId },
      });
    }
  }

  async toAddExpressOrderAddress(
    // addressMutation: string,
    // savedAddressGetter: string,
    // addressId: string,
    // title: string,
    // completingRedirectPath: string
    action: 'CreateItem' | 'ModifyItem',
    identity: 'Sender' | 'Receiver',
    templateId: string | '0',
  ) {
    let savedAddressGetter =
      identity === 'Sender' ? 'shipment/getShipmentSenderAddress' : 'shipment/getShipmentReceiverAddress';
    let addressId = '';
    if (templateId !== '0') {
      savedAddressGetter =
        identity === 'Sender'
          ? 'historialInput/expressOrder/getUserSavedSenderAddresses'
          : 'historialInput/expressOrder/getUserSavedReceiverAddresses';
      addressId = templateId;
    }

    await this.$router
      .push({
        //defetmine order type to different page
        name: 'ExpressOrderAddress',
        params: {
          addressMutation:
            identity === 'Sender' ? 'shipment/saveShipmentSenderAddress' : 'shipment/saveShipmentReceiverAddress',
          savedAddressGetter: savedAddressGetter,
          addressId: addressId,
          completingRedirectName: this.$route.name as string,
          title: ((this.$t('shipment.shipmentForm.addressForm.' + identity) as string) +
            this.$t('shipment.shipmentForm.addressForm.title2')) as string,
        },
      })
      .catch(err => {
        console.log(err);
      });
  }

  async toAddItem(
    // route to add item page
    action: 'CreateItem' | 'ModifyItem',
    itemId: number,
    templateItemId: number,
  ) {
    await this.$router.push({
      name: 'Item',
      params: {
        action,
        itemId: itemId.toString(),
        templateItemId: templateItemId.toString(),
      },
    });
  }

  async toAddExpressOrderItem(
    // route to add item page
    itemId: string,
    templateItemId: string,
  ) {
    await this.$router.push({
      name: 'ExpressOrderItem',
      params: { itemId: itemId, templateItemId: templateItemId },
    });
  }

  private async toPickUpStoreForm() {
    await this.$router.push({ name: 'PickUpStore' });
  }

  private setToPickUpStore(toPickUpStore: boolean) {
    this.$store.commit('shipment/setToPickUpStore', toPickUpStore);
    this.resetReceiverAddress();
    this.resetExpressOrderItems();
    if (toPickUpStore == false) {
      this.resetPickUpStore();
    }
    this.checkPaymentMethodAvailability();
  }
}
