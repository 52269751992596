var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qr-code-scanner-container" },
    [
      _c("TheMDCIconButton", {
        ref: "returnButton",
        staticClass: "return__button",
        attrs: {
          color: "#FF4124",
          reference: "returnButton",
          icon: "close",
          event: "hide",
          height: "45px"
        },
        on: { hide: _vm.hide }
      }),
      _c("div", { staticClass: "decode-result" }),
      _c("div", { staticClass: "qr-code-wrapper" }),
      !_vm.destroyed
        ? _c("qrcode-stream", {
            staticClass: "qr-code-item",
            on: { decode: _vm.onDecode, init: _vm.onInit }
          })
        : _vm._e(),
      _c("div", { staticClass: "mdc-layout-grid qr-code-form-container" }, [
        _c(
          "div",
          { staticClass: "qr-code-form__inner mdc-layout-grid__inner" },
          [
            _c(
              "div",
              {
                staticClass:
                  "form-label qr-code-form-title__container mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "shipment.shipmentForm.qrCodeScanner.scanOrInputTrackingNumber"
                      )
                    ) +
                    "\n      "
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "mdc-layout-grid__cell--span-3-phone mdc-layout-grid__cell--span-6-tablet mdc-layout-grid__cell--span-9-desktop"
              },
              [
                _c("TheMDCTextFieldOutlined", {
                  ref: "inputTrackingNumber",
                  attrs: {
                    reference: "inputTrackingNumber",
                    label: _vm.$t(
                      "shipment.shipmentForm.qrCodeScanner.inputTrackingNumber"
                    ),
                    type: "text",
                    isRequired: false,
                    maxlength: "14",
                    width: "100%"
                  },
                  model: {
                    value: _vm.inputTrackingNumber,
                    callback: function($$v) {
                      _vm.inputTrackingNumber = $$v
                    },
                    expression: "inputTrackingNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "submit-input-tracking-number-button-container mdc-layout-grid__cell--span-1-phone mdc-layout-grid__cell--span-2-tablet mdc-layout-grid__cell--span-3-desktop"
              },
              [
                _c("TheMDCButtonShapedRaised", {
                  ref: "submitInputTrackingNumber",
                  attrs: {
                    reference: "submitInputTrackingNumber",
                    label: _vm.$t(
                      "shipment.shipmentForm.qrCodeScanner.confirm"
                    ),
                    fontSize: "min(3vw, 1rem)",
                    event: "submit-input-tracking-number",
                    isDisabled: !_vm.validateTrackingNumberFormat(
                      _vm.inputTrackingNumber
                    )
                  },
                  on: {
                    "submit-input-tracking-number": function($event) {
                      return _vm.submitInputTrackingNumber(
                        _vm.inputTrackingNumber
                      )
                    }
                  }
                })
              ],
              1
            )
          ]
        )
      ]),
      _c("Prompt", {
        ref: "qr-code-error-prompt",
        attrs: { event: "reload-qr-code" },
        on: { "reload-qr-code": _vm.reload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }