






























































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import TheMDCButtonShaped from "@/components/mdcComponents/buttons/TheMDCButtonShaped.vue";
import TheMDCButtonOutlined from "@/components/mdcComponents/buttons/TheMDCButtonOutlined.vue";
import TheMDCButtonShapedRaised from "@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue";
import TheMDCIconButton from "@/components/mdcComponents/buttons/TheMDCIconButton.vue";
import { fetchLabelUrl } from "@/utils/fetchDataHelpers";
import { TrackingHelpers } from "@/utils/trackingHelpers.ts";
import App from "@/App.vue";
import { saveFileTODevice } from "../../utils/saveFileToDevice";
import * as TranslationHelpers from "@/utils/translationHelpers";
import { ShipmentHelpers } from "@/utils/shipmentHelpers";
import DateFormatHelpers from "@/utils/dateFormatHelpers";
import { trueOrZero } from "@/utils/compareHelpers";
import { snakeCase } from 'snake-case';





@Component({
  components: {
    TheMDCButtonShaped,
    TheMDCButtonShapedRaised,
    TheMDCButtonOutlined,
    TheMDCIconButton,
  },
})
export default class ShipmentDetail extends Vue implements VuePromptCaller {
  public on(event: 'accept' | 'close', payload?: any, execution?: CallBack<void> | null) {
    if (event === 'accept') {
      if (execution) {
        execution(event, payload);
      }
    }
  }
  beforeCreated() {
    // this.$store.dispatch("tracking/retrieveShipmentRecordById",this.trackingOrderId);
  }
  get text() {
    return {
      // title: 'Confirmation',
      shipmentDetail: this.$t('tracking.trackingDetail.shipmentDetail'),
      originDetail: this.$t('tracking.trackingDetail.originDetail'),
      destinationDetail: this.$t('tracking.trackingDetail.destinationDetail'),
      deliveryDetail: this.$t('tracking.trackingDetail.deliveryDetail'),
      checkpointDetail: this.$t('tracking.trackingDetail.checkpointDetail'),
      itemDetail: this.$t('tracking.trackingDetail.itemDetail'),

      // label
      yes: this.$t('tracking.trackingDetail.yes'),
      no: this.$t('tracking.trackingDetail.no'),
      orderNotes: this.$t('tracking.trackingDetail.orderNotes'),
      refNo: this.$t('tracking.trackingDetail.refNo'),
      trackingNumber: this.$t('tracking.trackingDetail.trackingNumber'),
      mainTrackingNumber: this.$t('tracking.trackingDetail.mainTrackingNumber'),
      itemTrackingNumber: this.$t('tracking.trackingDetail.itemTrackingNumber'),
      shipmentConfirmDate: this.$t('tracking.trackingDetail.shipmentConfirmDate'),
      from: this.$t('tracking.trackingDetail.from'),
      destination: this.$t('tracking.trackingDetail.destination'),
      contact: this.$t('tracking.trackingDetail.contact'),
      email: this.$t('tracking.trackingDetail.email'),
      address: this.$t('tracking.trackingDetail.address'),
      balance: this.$t('tracking.trackingDetail.balance'),

      items: this.$t('tracking.trackingDetail.items'),
      isInsured: this.$t('tracking.trackingDetail.isInsured'),
      orderType: this.$t('tracking.trackingDetail.orderType'),
      taxesDutiesPaidBy: this.$t('tracking.trackingDetail.taxesDutiesPaidBy'),
      shipmentFee: this.$t('tracking.trackingDetail.shipmentFee'),
      courier: this.$t('tracking.trackingDetail.courier'),
      pickupOption: this.$t('tracking.trackingDetail.pickupOption'),
      pickupTime: this.$t('tracking.trackingDetail.pickupTime'),
      location: this.$t('tracking.trackingDetail.location'),
      message: this.$t('tracking.trackingDetail.message'),
      checkpointTime: this.$t('tracking.trackingDetail.checkpointTime'),
      category: this.$t('tracking.trackingDetail.category'),
      declaredCustomsValue: this.$t('tracking.trackingDetail.declaredCustomsValue'),
      dimension: this.$t('tracking.trackingDetail.dimension'),
      lwh: this.$t('tracking.trackingDetail.lwh'),
      senderCharge: this.$t('tracking.trackingDetail.senderCharge'),
      recipientCharge: this.$t('tracking.trackingDetail.recipientCharge'),
      paymentMethod: this.$t('shipment.paymentMethod.paymentMethod'),
      cashOnDeliveryAmount: this.$t('shipment.paymentMethod.cashOnDeliveryAmount'),
      remarks: this.$t('general.remarks'),

      // text
      currency: this.$t('tracking.trackingDetail.currency'),
      pickup: this.$t('tracking.trackingDetail.pickup'),
      markAsDirectlyHandover: this.$t('tracking.trackingDetail.markAsDirectlyHandover'),
      to: this.$t('tracking.trackingDetail.to'),
      noCheckpointShown: this.$t('tracking.trackingDetail.noCheckpointShown'),
      notesToMerchant: this.$t('tracking.trackingDetail.notesToMerchant'),
      expressCreatedAt: this.$t('tracking.trackingDetail.expressCreatedAt'),
      itemInfoNotRequired: this.$t('tracking.trackingDetail.itemInfoNotRequired'),
      shipmentOrder: this.$t('tracking.trackingList.trackingOrderType.shipmentOrder'),
      expressOrder: this.$t('tracking.trackingList.trackingOrderType.expressOrder'),
      documentDelivery: this.$t('tracking.trackingList.trackingOrderType.documentDelivery'),

      // buttons
      return: this.$t('tracking.trackingDetail.return'),
      duplicateShipment: this.$t('tracking.trackingDetail.duplicateShipment'),
      exportLabel: this.$t('tracking.trackingDetail.exportLabel'),
      exportAllLabel: this.$t('tracking.trackingDetail.exportAllLabel'),
      //sign
      coma: this.$t('tracking.trackingDetail.coma'),
      multiplySign: this.$t('tracking.trackingDetail.multiplySign'),
      hyphen: this.$t('tracking.trackingDetail.hyphen'),
      pickUpStoreTrackingNumber: this.$t('tracking.trackingDetail.pickUpStoreTrackingNumber'),
      externalOrderNumber: this.$t('tracking.trackingDetail.externalOrderNumber'),
    };
  }

  mounted() {
    this.$store.commit('hideNavBar');
    this.$store.commit('hideTrackingTitle');
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit('hideLoading');
    });
  }

  get trackingOrderId() {
    return this.$route.params.shipmentId;
  }
  get shipmentRecord() {
    if (this.isExpress) {
      return this.$store.getters['tracking/getExpressRecord'];
    } else {
      return this.$store.getters['tracking/getShipmentRecord'];
    }
  }
  get thisDate() {
    const now = new Date();
    return now;
  }

  get trackingOrderType() {
    return this.$store.getters['tracking/getTrackingOrderType'];
  }

  get isExpress() {
    if (this.trackingOrderType == 'express') {
      return true;
    } else {
      return false;
    }
  }
  get isShipment() {
    if (this.trackingOrderType == 'shipment') {
      return true;
    } else {
      return false;
    }
  }

  get isDocument() {
    // return false;
    if (this.isExpress && this.shipmentRecord.isDocument) {
      return true;
    } else {
      return false;
    }
  }
  get toPickUpStore() {
    return !!this.shipmentRecord.isSelfPickUp;
  }

  get paymentMethodText() {
    return TranslationHelpers.paymentMethodText(this.shipmentRecord.paymentMethod);
  }

  get senderCharge() {
    if (this.isExpress) {
      if (
        this.shipmentRecord.paymentMethod === 'PAID_BY_MERCHANT' ||
        this.shipmentRecord.paymentMethod === 'PAID_BY_MERCHANT_COD'
      ) {
        return this.shipmentRecord.totalCharge;
      } else {
        return '0';
      }
    }
    return '-';
  }

  get recipientCharge() {
    if (this.isExpress) {
      if (this.shipmentRecord.paymentMethod === 'CASH_ON_DELIVERY') {
        return Number(this.shipmentRecord.totalCharge) + Number(this.shipmentRecord.cashOnDeliveryAmount);
      } else if (this.shipmentRecord.paymentMethod === 'PAID_BY_MERCHANT_COD') {
        return this.shipmentRecord.cashOnDeliveryAmount;
      } else if (this.shipmentRecord.paymentMethod === 'PAID_BY_RECEIVER') {
        return this.shipmentRecord.totalCharge;
      } else {
        return '0';
      }
    }
    return '-';
  }

  get originAddressString() {
    return this.isExpress
      ? ShipmentHelpers.displayExpressOrderAddress(this.shipmentRecord.origin)
      : ShipmentHelpers.displayAddress(this.shipmentRecord.origin);
  }

  get destinationAddressString() {
    return this.isExpress
      ? this.toPickUpStore
        ? ShipmentHelpers.displayPickUpStore(
            this.$store.getters['pickUpStore/getPickUpStoreById'](this.shipmentRecord?.pickUpStore?.id),
          )
        : ShipmentHelpers.displayExpressOrderAddress(this.shipmentRecord.destination)
      : ShipmentHelpers.displayAddress(this.shipmentRecord.destination);
  }

  get deliveryDate() {
    const deliveryDateString = this.shipmentRecord.deliveryDate;
    if (deliveryDateString) {
      return DateFormatHelpers.formattedStringFromDateString(deliveryDateString, 'date');
    }
    return this.$t('general.notSpecified');
  }
  get pickUpDate() {
    const pickUpDateString = this.shipmentRecord.pickUpDate;
    if (pickUpDateString) {
      return DateFormatHelpers.formattedStringFromDateString(pickUpDateString, 'date');
    }
    return this.$t("general.notSpecified");
  }
   adjustedDimension(item: ExpressOrderItem) {
    return trueOrZero(item?.adjustedLength) && trueOrZero(item?.adjustedWidth) && trueOrZero(item?.adjustedHeight)
      ? `${item.adjustedLength}(L)cm x ${item.adjustedWidth}(W)cm x ${item.adjustedHeight}(H)cm = ${Number(
          item.adjustedLength,
        ) *
          Number(item.adjustedWidth) *
          Number(item.adjustedHeight)}`
      : '';
  }

  extraChargeFactor(item: ExpressOrderItem){
    return item.extraChargeFactor && this.$te(`tracking.trackingDetail.${item.extraChargeFactor.toLowerCase()}`)
      ? this.$t(`tracking.trackingDetail.${item.extraChargeFactor.toLowerCase()}`)
      : '-';
  }

  private formatTimeString(dateString: string): string {
    return DateFormatHelpers.formattedStringFromDateString(dateString, "dateTime");
  }

  duplicateShipment() {
    TrackingHelpers.duplicateShipmentRecord(this.trackingOrderId);
  }
  async exportLabel(itemId?: string) {
    let url: string;
    if (itemId) {
      url = await fetchLabelUrl(itemId, this.isExpress ? 'express' : 'otherCourier', 'expressOrderItem');
    } else {
      url = await fetchLabelUrl(this.shipmentRecord.id, this.isExpress ? 'express' : 'otherCourier', 'expressOrder');
    }
    const ROOT = this.$root.$children[0] as App;
    if (url) {
      ROOT.showPrompt({
        msg: this.$t('tracking.trackingExport.exportLabel') as string,
        acceptBlt: this.$t('tracking.trackingExport.export') as string,
        listener: this,
        execution: () => {
          saveFileTODevice(url);
        },
      });
    } else {
      this.$store.commit('showConnectionError');
    }
    // const link = document.createElement("a");
    // link.href = url;
    // link.target = "_blank";
    // link.click();
    // link.remove();
  }
  // toLastPage() {
  //   this.$router.back();
  // }
  async backToShipmentList() {
    await this.$router.push({
      name: 'TrackingList',
    });
  }
}
