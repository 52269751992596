var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shipment-form__container" }, [
    _c(
      "div",
      { staticClass: "inner-shipment-form__container grid-container" },
      [
        _c("div", { staticClass: "grid-item-subprime-blt" }, [
          _c(
            "div",
            {
              staticClass: "form-label sub-title",
              class: { "form-label--filled": _vm.isSubprime }
            },
            [_vm._v(_vm._s(_vm.text.isSubprimeTitle) + "\n      ")]
          ),
          _c(
            "div",
            { staticClass: "subprime-form inner-grid-subprime-container" },
            [
              _vm.isSubprime
                ? _c("div", { staticClass: "shipment-form__row" }, [
                    _c(
                      "div",
                      { staticClass: "shipment-form__field" },
                      [
                        _c("TheMDCSwitch", {
                          class: _vm.isDocument
                            ? "swtich-color--activate"
                            : "swtich-color",
                          attrs: {
                            reference: "isDocument",
                            label: _vm.text.isDocument,
                            isChecked: _vm.isDocument,
                            event: "set-is-document",
                            icon: "info",
                            fontSize: "min(1rem, 4vw)",
                            promptData: _vm.isDocumentPromptData
                          },
                          on: { "set-is-document": _vm.setIsDocument }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isDocument,
                      expression: "isDocument"
                    }
                  ],
                  staticClass: "shipment-form__row date-picker-container"
                },
                [
                  _c("TheMDCSelectOutlined", {
                    ref: "deliveryDate",
                    staticStyle: { margin: "0 auto" },
                    attrs: {
                      reference: "deliveryDate",
                      label: _vm.$t(
                        "shipment.shipmentForm.shipmentForm.deliveryDate"
                      ),
                      isRequired: true,
                      menuList: _vm.deliveryDateMenuList,
                      height: "50px",
                      event: "set-delivery-date"
                    },
                    on: { "set-delivery-date": _vm.setDeliveryDate }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isDocument,
                      expression: "isDocument"
                    }
                  ],
                  staticClass: "shipment-form__row date-picker-container"
                },
                [
                  _c("TheMDCSelectOutlined", {
                    ref: "pickUpDate",
                    staticStyle: { margin: "0 auto" },
                    attrs: {
                      reference: "pickUpDate",
                      label:
                        _vm.$t(
                          "shipment.shipmentForm.shipmentForm.pickUpDate"
                        ) +
                        _vm.$t("general.leftBracket") +
                        _vm.$t(
                          "shipment.shipmentForm.shipmentForm.selectDeliveryDateFirst"
                        ) +
                        _vm.$t("general.rightBracket"),
                      isRequired: true,
                      menuList: _vm.pickUpDateMenuList,
                      height: "50px",
                      event: "set-pickup-date"
                    },
                    on: { "set-pickup-date": _vm.setPickUpDate }
                  })
                ],
                1
              ),
              _vm.isSubprime
                ? _c(
                    "div",
                    { staticClass: "shipment-form__row" },
                    [
                      _c("TheMDCTextFieldOutlined", {
                        ref: "orderNotes",
                        attrs: {
                          reference: "orderNotes",
                          label: _vm.text.orderNotesPlaceholder,
                          type: "text",
                          isRequired: false,
                          maxlength: 70,
                          showCounter: true,
                          event: "set-express-order-remarks"
                        },
                        on: { "set-express-order-remarks": _vm.setOrderNotes }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ]),
        _c(
          "div",
          { staticClass: "address-form__container grid-item-addresses" },
          [
            _c(
              "div",
              {
                staticClass: "form-label sub-title",
                class: {
                  "form-label--filled": _vm.hasSender && _vm.hasReceiver
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.text.shippingAddresses) + "\n      "
                )
              ]
            ),
            _c("TheMDCSwitch", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isDocument,
                  expression: "!isDocument"
                }
              ],
              class: _vm.toPickUpStore
                ? "swtich-color--activate"
                : "swtich-color",
              attrs: {
                reference: "toPickUpStore",
                label: _vm.$t("pickUpStore.toPickUpStore"),
                isChecked: _vm.toPickUpStore,
                event: "set-to-pick-up-store",
                icon: "info",
                fontSize: "min(1rem, 4vw)"
              },
              on: { "set-to-pick-up-store": _vm.setToPickUpStore }
            }),
            _c(
              "div",
              { staticClass: "address-form inner-grid-list-container" },
              [
                _c("div", { staticClass: "shipment-form__row" }, [
                  _c(
                    "div",
                    { staticClass: "shipment-form__field" },
                    [
                      _c("TheMDCButtonOutlined", {
                        ref: "senderBlt",
                        class: _vm.hasSender
                          ? "shipment-form-color--activate"
                          : "shipment-form-color",
                        attrs: {
                          reference: "senderBlt",
                          event: "modify-sender",
                          startIcon: _vm.hasSender ? "send" : "add",
                          label: _vm.hasSender
                            ? _vm.senderInfo
                            : _vm.text.addSender,
                          fontSize: "min(1.5rem, 4vw)"
                        },
                        on: {
                          "modify-sender": function($event) {
                            return _vm.showAddShipmentDataPrompt(
                              _vm.hasSender ? "ModifyItem" : "CreateItem",
                              "Sender"
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.toPickUpStore,
                        expression: "!toPickUpStore"
                      }
                    ],
                    staticClass: "shipment-form__row"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "shipment-form__field" },
                      [
                        _c("TheMDCButtonOutlined", {
                          ref: "receiverBlt",
                          class: _vm.hasReceiver
                            ? "shipment-form-color--activate"
                            : "shipment-form-color",
                          attrs: {
                            reference: "receiverBlt",
                            event: "modify-receiver",
                            startIcon: _vm.hasReceiver
                              ? "call_received"
                              : "add",
                            label: _vm.hasReceiver
                              ? _vm.receiverInfo
                              : _vm.text.addReceiver,
                            fontSize: "min(1.5rem, 4vw)"
                          },
                          on: {
                            "modify-receiver": function($event) {
                              return _vm.showAddShipmentDataPrompt(
                                _vm.hasReceiver ? "ModifyItem" : "CreateItem",
                                "Receiver"
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.toPickUpStore,
                        expression: "toPickUpStore"
                      }
                    ],
                    staticClass: "shipment-form__row"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "shipment-form__field" },
                      [
                        _c("TheMDCButtonOutlined", {
                          ref: "pickUpStoreButton",
                          class: _vm.pickUpStoreId
                            ? "shipment-form-color--activate"
                            : "shipment-form-color",
                          attrs: {
                            reference: "pickUpStoreButton",
                            event: "to-pick-up-store-form",
                            startIcon: "other_houses",
                            label: _vm.pickUpStoreId
                              ? _vm.pickUpStoreContactInfo
                              : _vm.$t("pickUpStore.selectPickUpStore"),
                            fontSize: "min(1.5rem, 4vw)"
                          },
                          on: { "to-pick-up-store-form": _vm.toPickUpStoreForm }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          ],
          1
        ),
        _vm.isSubprime
          ? _c(
              "div",
              {
                staticClass:
                  "payment-method__container grid-item-payment-method"
              },
              [
                _c("div", { staticClass: "shipment-form__row " }, [
                  _c(
                    "div",
                    {
                      staticClass: "shipment-form__field shipment-form__radio"
                    },
                    [
                      _c("TheMDCRadioButton", {
                        ref: "paymentMethodPaidByMerchant",
                        attrs: {
                          reference: "paymentMethodPaidByMerchant",
                          name: "paymentMethod",
                          value: "PAID_BY_MERCHANT",
                          default: _vm.paymentMethod === "PAID_BY_MERCHANT",
                          label: _vm.text.paidByMerchant,
                          event: "set-payment-method"
                        },
                        on: { "set-payment-method": _vm.setPaymentMethod }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { attrs: { calss: "shipment-form__row" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "shipment-form__field shipment-form__radio"
                    },
                    [
                      _c("TheMDCRadioButton", {
                        ref: "paymentMethodPaidByRecipient",
                        attrs: {
                          reference: "paymentMethodPaidByRecipient",
                          name: "paymentMethod",
                          value: "PAID_BY_RECEIVER",
                          default: _vm.paymentMethod === "PAID_BY_RECEIVER",
                          label: _vm.text.paidByRecipient,
                          isDisabled: _vm.pickUpStoreCannotCollectCash,
                          event: "set-payment-method"
                        },
                        on: { "set-payment-method": _vm.setPaymentMethod }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "shipment-form__row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "shipment-form__field shipment-form__radio"
                    },
                    [
                      _c("TheMDCRadioButton", {
                        ref: "paymentMethodCashOnDelivery",
                        attrs: {
                          reference: "paymentMethodCashOnDelivery",
                          name: "paymentMethod",
                          value: "CASH_ON_DELIVERY",
                          default: _vm.paymentMethod === "CASH_ON_DELIVERY",
                          label: _vm.text.cashOnDelivery,
                          isDisabled: _vm.pickUpStoreCannotCollectCash,
                          event: "set-payment-method"
                        },
                        on: { "set-payment-method": _vm.setPaymentMethod }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "shipment-form__row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "shipment-form__field shipment-form__radio"
                    },
                    [
                      _c("TheMDCRadioButton", {
                        ref: "paymentMethodPaidByMerchantCashOnDelivery",
                        attrs: {
                          reference:
                            "paymentMethodPaidByMerchantCashOnDelivery",
                          name: "paymentMethod",
                          value: "PAID_BY_MERCHANT_COD",
                          default: _vm.paymentMethod === "PAID_BY_MERCHANT_COD",
                          label: _vm.text.paidByMerchantCashOnDelivery,
                          isDisabled: _vm.pickUpStoreCannotCollectCash,
                          event: "set-payment-method"
                        },
                        on: { "set-payment-method": _vm.setPaymentMethod }
                      })
                    ],
                    1
                  )
                ]),
                _vm.paymentMethod === "CASH_ON_DELIVERY" ||
                _vm.paymentMethod === "PAID_BY_MERCHANT_COD"
                  ? _c(
                      "div",
                      { staticClass: "shipment-form__row" },
                      [
                        _c("TheMDCTextFieldOutlined", {
                          ref: "cashOnDeliveryAmount",
                          attrs: {
                            reference: "cashOnDeliveryAmount",
                            label: _vm.text.cashOnDeliveryAmountPlaceholder,
                            type: "integer",
                            isRequired: true,
                            event: "set-cash-on-delivery-item-amount"
                          },
                          on: {
                            "set-cash-on-delivery-item-amount":
                              _vm.setCashOnDeliveryAmount
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.paymentMethod === "CASH_ON_DELIVERY" ||
                _vm.paymentMethod === "PAID_BY_MERCHANT_COD"
                  ? _c("div", { staticClass: "reminder" }, [
                      _vm._v(
                        "\n        *" +
                          _vm._s(_vm.text.cashOnDeliveryAmountReminder) +
                          "\n    "
                      )
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "delivery-form__container grid-item-delivery" },
          [
            _c(
              "div",
              {
                staticClass: "form-label sub-title",
                class: { "form-label--filled": _vm.hasItem }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.text.deliveryDetail) + "\n      "
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "delivery-form inner-grid-delivery-container" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isSubprime,
                        expression: "!isSubprime"
                      }
                    ],
                    staticClass: "shipment-form__row inner-grid-list-container"
                  },
                  [
                    _vm._l(_vm.items, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.itemId,
                          staticClass: "shipment-form__field",
                          class: { "inner-grid-row-container": _vm.hasItem }
                        },
                        [
                          _c("TheMDCButtonOutlined", {
                            ref: item.itemId,
                            refInFor: true,
                            staticClass:
                              "left-item shipment-form-color--activate",
                            staticStyle: { "vertical-align": "middle" },
                            attrs: {
                              reference: item.itemId,
                              event: "modify-item",
                              label: _vm.getItemInfo(item.itemId),
                              startIcon: "local_post_office",
                              fontSize: "min(1.5rem, 4vw)"
                            },
                            on: {
                              "modify-item": function($event) {
                                return _vm.showAddShipmentDataPrompt(
                                  "ModifyItem",
                                  item.itemId
                                )
                              }
                            }
                          }),
                          _c("TheMDCIconbutton", {
                            staticClass: "right-item",
                            staticStyle: { "vertical-align": "middle" },
                            attrs: {
                              reference: "delete-" + item.itemId,
                              icon: "delete",
                              event: "prompt-del-item"
                            },
                            on: {
                              "prompt-del-item": function($event) {
                                return _vm.showDelItemPromp(item.itemId)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    _c(
                      "div",
                      { staticClass: "shipment-form__field" },
                      [
                        _c("TheMDCButtonOutlined", {
                          ref: "itemBlt",
                          staticClass: "shipment-form-color",
                          attrs: {
                            label: _vm.text.addItem,
                            reference: "itemBlt",
                            event: "add-item",
                            startIcon: "add",
                            fontSize: "min(1.5rem, 4vw)"
                          },
                          on: {
                            "add-item": function($event) {
                              return _vm.showAddShipmentDataPrompt(
                                "CreateItem",
                                -1
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _vm.thirdPartyItemLimitReached
                  ? _c("div", { staticClass: "reminder" }, [
                      _vm._v(
                        "*" +
                          _vm._s(
                            _vm.$t(
                              "shipment.shipmentForm.shipmentForm.multipleItemNotAvailableForThirdParty"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isSubprime,
                        expression: "isSubprime"
                      }
                    ],
                    staticClass: "shipment-form__row  inner-grid-list-container"
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isSubprime,
                            expression: "isSubprime"
                          }
                        ],
                        staticClass: "shipment-form__field"
                      },
                      [
                        _c("TheMDCButtonOutlined", {
                          ref: "expressOrderItem",
                          staticClass: "shipment-form-color",
                          attrs: {
                            label: _vm.text.addItem,
                            reference: "expressOrderItem",
                            startIcon: "add",
                            event: "add-express-order-item",
                            fontSize: "min(1.5rem, 4vw)",
                            isDisabled: _vm.thirdPartyItemLimitReached
                          },
                          on: {
                            "add-express-order-item": function($event) {
                              return _vm.showAddShipmentDataPrompt(
                                "CreateItem",
                                "-1"
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._l(_vm.expressOrderItems, function(expressOrderItem) {
                      return _c(
                        "div",
                        { key: expressOrderItem.trackingNumber },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "shipment-form__field",
                              class: {
                                "inner-grid-row-container": expressOrderItem
                              }
                            },
                            [
                              _c("TheMDCButtonOutlined", {
                                ref: "expressOrderItem",
                                refInFor: true,
                                staticClass:
                                  "left-item shipment-form-color--activate",
                                staticStyle: { "vertical-align": "middle" },
                                attrs: {
                                  reference: "expressOrderItem",
                                  event: "modify-item",
                                  label: _vm.expressOrderItemDisplay(
                                    expressOrderItem
                                  ),
                                  startIcon: "local_post_office",
                                  fontSize: "min(1.5rem, 4vw)"
                                },
                                on: {
                                  "modify-item": function($event) {
                                    return _vm.showAddShipmentDataPrompt(
                                      expressOrderItem
                                        ? "ModifyItem"
                                        : "CreateItem",
                                      expressOrderItem.trackingNumber
                                    )
                                  }
                                }
                              }),
                              _c("TheMDCIconbutton", {
                                ref: "deleteExpressOrderItem",
                                refInFor: true,
                                staticClass: "right-item",
                                staticStyle: { "vertical-align": "middle" },
                                attrs: {
                                  reference: "deleteExpressOrderItem",
                                  icon: "delete",
                                  event: "prompt-del-item"
                                },
                                on: {
                                  "prompt-del-item": function($event) {
                                    return _vm.showDelExpressOrderItemPromp(
                                      expressOrderItem.trackingNumber
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isSubprime,
                        expression: "!isSubprime"
                      }
                    ],
                    staticClass: "shipment-form__row"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "shipment-form__field" },
                      [
                        _c("TheMDCSwitch", {
                          class: _vm.isInsured
                            ? "swtich-color--activate"
                            : "swtich-color",
                          attrs: {
                            reference: "isInsured",
                            label: _vm.text.isInsured,
                            isChecked: _vm.isInsured,
                            event: "set-insured",
                            fontSize: "min(1rem, 4vw)",
                            icon: "info",
                            promptData: _vm.insurancePromptData
                          },
                          on: { "set-insured": _vm.setInsured }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isSubprime,
                        expression: "!isSubprime"
                      }
                    ],
                    staticClass: "shipment-form__row"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "shipment-form__field" },
                      [
                        _c("TheMDCSwitch", {
                          class: _vm.taxPaidBy
                            ? "swtich-color--activate"
                            : "swtich-color",
                          attrs: {
                            reference: "taxPaidBy",
                            isChecked: _vm.taxPaidBy,
                            label: _vm.text.taxDutyPaidBy,
                            event: "set-tax-paid-by",
                            fontSize: "min(1rem, 4vw)",
                            icon: "info",
                            promptData: _vm.taxPromptData
                          },
                          on: { "set-tax-paid-by": _vm.setTaxPaidBy }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "button-row__container grid-item-proceed-button" },
          [
            _c("div", { staticClass: "button-row" }, [
              _c(
                "div",
                {
                  staticClass: "shipment-form__row inner-grid-button-container"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "shipment-form__field left-item inner-button"
                    },
                    [
                      _c("TheMDCButtonShaped", {
                        ref: "reset",
                        attrs: {
                          label: _vm.text.reset,
                          reference: "reset",
                          event: "reset"
                        },
                        on: {
                          reset: function($event) {
                            return _vm.reset()
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "shipment-form__field right-item inner-button"
                    },
                    [
                      _c("TheMDCButtonShapedRaised", {
                        ref: "proceed",
                        attrs: {
                          label: _vm.text.proceed,
                          reference: "proceed",
                          event: "proceed",
                          trailIcon: "keyboard_arrow_right",
                          isDisabled: !_vm.canProceed
                        },
                        on: {
                          proceed: function($event) {
                            return _vm.proceed()
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }