





























import { Component, Prop, Vue } from "vue-property-decorator";
import {MDCFormField} from '@material/form-field';
import {MDCRipple} from "@material/ripple";
import {MDCRadio} from '@material/radio';
import  * as theme from '@/assets/scss/_theme.scss';


@Component
export default class TheMDCRadioButton extends Vue {
  @Prop() reference!: string;
  @Prop() name!: string;
  @Prop() value!: string;
  @Prop({default: false}) default?: boolean;
  @Prop() event?: string;
  @Prop() label!: string;
  @Prop() color?: string;
  @Prop() font?: string;
  @Prop() isDisabled!: boolean | undefined;

  get theme(){
    return theme;
  }


// public mounted() {
//     const iconButtonRipple = new MDCRipple(
//       this.$refs[this.reference as string] as Element
//     );s
//     iconButtonRipple.unbounded = true;
//   }
  private radioButton!: MDCRadio;

  mounted() {
    this.radioButton = new MDCRadio(
      this.$refs[this.reference as any] as Element
    );
    if (this.default) {
      this.setChecked(true);
    }
  }



  private emitToParent() {
    if (this.event) {
      this.$emit(this.event);
    }
  }

  setChecked(toggle: boolean){
    this.radioButton.checked = toggle;
  }
}
