



























































import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import TheMDCTextFieldOutlined from '@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue';
import TheMDCButtonOutlined from '@/components/mdcComponents/buttons/TheMDCButtonOutlined.vue';
import TheMDCButtonShapedRaised from '@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue';
import TheMDCIconButton from '@/components/mdcComponents/buttons/TheMDCIconButton.vue';
import Prompt from '@/components/prompts/Prompt.vue';

import App from '@/App.vue';
import VueQrcodeReader from 'vue-qrcode-reader';
import { ShipmentHelpers } from '@/utils/shipmentHelpers';
Vue.use(VueQrcodeReader);
@Component({
  components: {
    TheMDCTextFieldOutlined,
    TheMDCButtonOutlined,
    TheMDCButtonShapedRaised,
    VueQrcodeReader,
    TheMDCIconButton,
    Prompt,
  },
})
export default class QrCode extends Vue {
  @Ref('qr-code-error-prompt') readonly qrCodeErrorPromptComponent!: Prompt;
  @Prop() templateItemId!: string; //For backtracking

  private result = '';
  private destroyed = false;
  private loading = false;
  private cameraError = false;

  created() {
    this.$store.commit('hideTitle');
    this.$store.commit('hideTopBar');
    this.$store.commit('hideNavBar');
  }
  mounted() {
    // this.onInit();
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      // this.$store.commit("hideLoading");
    });
  }
  private ROOT = this.$root.$children[0] as App;

  private async onInit(promise: any): Promise<void> {
    this.loading = true;
    this.$store.commit('showLoading');
    try {
      const { capabilities } = await promise;
    } catch (error) {
      let errorMessage = '';
      this.cameraError = true;
      if (error.name === 'NotAllowedError') {
        errorMessage = this.$t('shipment.shipmentForm.qrCodeScanner.error.notAllowedError') as string;
      } else if (error.name === 'NotFoundError') {
        errorMessage = this.$t('shipment.shipmentForm.qrCodeScanner.error.notFoundError') as string;
      } else if (error.name === 'NotSupportedError') {
        errorMessage = this.$t('shipment.shipmentForm.qrCodeScanner.error.notSupportedError') as string;
      } else if (error.name === 'NotReadableError') {
        errorMessage = this.$t('shipment.shipmentForm.qrCodeScanner.error.notReadableError') as string;
      } else if (error.name === 'OverconstrainedError') {
        errorMessage = this.$t('shipment.shipmentForm.qrCodeScanner.error.overconstrainedError') as string;
      }
      //  else{
      //   errorMessage = this.$t("shipment.shipmentForm.qrCodeScanner.error.commonError") as string;
      // }
      if (errorMessage) {
        this.showQrCodeError(errorMessage);
      }
    } finally {
      this.loading = false;
      this.$store.commit('hideLoading');
    }
  }

  private async onDecode(result: string) {
    //hide loading
    this.loading = true;
    this.$store.commit('showLoading');

    let trackingNumber = '';
    if (result.includes(process.env.VUE_APP_FRONTEND_BASE_URL as string)) {
      this.result = result;
      const resultArray = this.result.split('/');
      trackingNumber = resultArray[resultArray.length - 1];
      await this.submitTrackingNumber(trackingNumber);
    } else {
      this.showQrCodeError(this.$t('shipment.shipmentForm.qrCodeScanner.error.notJumppointQrCode') as string);
    }
    //hide loading
    this.loading = false;
    this.$store.commit('hideLoading');

    // this.$router.push({ name: "Shipment", params: { shipmentId: shipmentId } })
  }

  async reload() {
    if (!this.cameraError) {
      this.destroyed = true;
      await this.$nextTick();
      this.destroyed = false;
    }
  }

  get isDocument() {
    return this.$store.getters['shipment/isDocument'];
  }

  validateTrackingNumberFormat(trackingNumber: string): boolean {
    return ShipmentHelpers.validateExpressOrderTrackingNumberFormat(trackingNumber);
  }
  private inputTrackingNumber = '';

  async submitInputTrackingNumber() {
    await this.submitTrackingNumber(this.inputTrackingNumber);
  }

  async submitTrackingNumber(trackingNumber: string) {
    let validateResult;
    let errorMessage;
    if (trackingNumber) {
      if (this.validateTrackingNumberFormat(trackingNumber) === true) {
        validateResult = await ShipmentHelpers.validateTrackingNumeberWithType(trackingNumber, this.isDocument);
        errorMessage = validateResult;
        if (validateResult === true) {
          const trackingNumberInUse = this.$store.getters['shipment/getExpressOrderItems'].map(
            (item: ExpressOrderItem) => item.trackingNumber,
          );
          if (ShipmentHelpers.validateExpressOrderTrackingNumberDuplicate(trackingNumberInUse, trackingNumber)) {
            this.$parent.$data.savedItem.trackingNumber = trackingNumber;
            await this.$router.back();
            return;
          } else {
            errorMessage = this.$t('shipment.shipmentForm.qrCodeScanner.error.trackingNumberInUse') as string;
          }
        }
      }
    }
    await this.showQrCodeError(errorMessage);
    this.$store.commit('shipment/setTrackingNumber', '');
    // await this.reload();
  }

  private async hide() {
    this.$store.commit('showLoading');
    await this.$router.push({ path: 'expressOrderItem/-1' });
  }

  private showQrCodeError(errorMessage?: string): void {
    this.qrCodeErrorPromptComponent.show({
      // title: this.text.quoteRateError.title as string,
      // msg: this.text.quoteRateError.message as string,
      // closeBlt: this.$t("dialogs.close") as string
      title: this.$t('shipment.shipmentForm.qrCodeScanner.error.invalidLabel') as string,
      msg: errorMessage || (this.$t('shipment.shipmentForm.qrCodeScanner.error.invalidLabel') as string),
      closeBlt: this.$t('dialogs.close') as string,
    });
    // this.ROOT.showPrompt();
  }
}
