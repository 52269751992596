














import { Component, Prop, Vue } from "vue-property-decorator";
import TheTrackingTitle from "@/views/tracking/TheTrackingTitle.vue";

@Component({
  components: {
    TheTrackingTitle,
  },
})
export default class Shipment extends Vue {
  private text = {};
  beforeCreate() {
    this.$store.commit("showTopBar");
    this.$store.commit("showNavBar");
    this.$store.commit("showTrackingTitle");
  }

  get showTitle() {
    return this.$store.getters.showTrackingTitle;
  }

  // trackingList() {
  //   // (this.$refs["theTitle"] as TheTitle).toTrackingList();
  // }

  // trackingDetail() {
  //   // (this.$refs["theTitle"] as TheTitle).trackingDetail();
  // }
}
