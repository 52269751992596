
































































































import { Component, Prop, Vue } from 'vue-property-decorator';
// import { MDCCard } from "@material/card/mdc-card";
import { MDCRipple } from '@material/ripple';
import TheMDCButtonShaped from '@/components/mdcComponents/buttons/TheMDCButtonShaped.vue';
import TheMDCButtonShapedRaised from '@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue';
import { TrackingHelpers } from '@/utils/trackingHelpers';
import { fetchLabelUrl } from '@/utils/fetchDataHelpers';
import App from '@/App.vue';
import { saveFileTODevice } from '../../utils/saveFileToDevice';

const selector = '.mdc-button, .mdc-icon-button, .mdc-card__primary-action';

const ripples = [].map.call(document.querySelectorAll(selector), function(el) {
  return new MDCRipple(el);
});

@Component({
  components: {
    TheMDCButtonShaped,
    TheMDCButtonShapedRaised,
  },
})
export default class ShipmentRecordCard extends Vue implements VuePromptCaller {
  @Prop() shipment: any;
  @Prop() trackingOrderType!: TrackingOrderType;
  public on(event: 'accept' | 'close', payload?: any, execution?: CallBack<void> | null) {
    if (event === 'accept') {
      if (execution) {
        execution(event, payload);
      }
    }
  }
  private ripple!: MDCRipple;

  beforeCreate() {
    this.$store.commit('showLoading');
  }
  mounted() {
    this.$store.commit('hideLoading');
  }

  // private text = {
  //   shipmentId: "ID",
  //   externalShipmentId: "Tracking No",
  //   colon: " : ",
  //   to: " To ",
  //   labelIssueDate: "Label Issue Date",
  //   refNo: "Ref no.",
  //   origin: "Origin",
  //   destination: "Destination",

  //   //Button
  //   duplicateShipment: "Duplicate",
  //   exportLabel: "Label"
  // };
  get text() {
    return {
      shipmentId: this.$t('tracking.trackingList.shipmentListItem.shipmentId'),
      trackingNumber: this.$t('tracking.trackingList.shipmentListItem.trackingNumber'),
      mainTrackingNumber: this.$t('tracking.trackingList.shipmentListItem.mainTrackingNumber'),
      itemTrackingNumber: this.$t('tracking.trackingList.shipmentListItem.itemTrackingNumber'),
      colon: this.$t('tracking.trackingList.shipmentListItem.colon'),
      to: this.$t('tracking.trackingList.shipmentListItem.to'),
      labelIssueDate: this.$t('tracking.trackingList.shipmentListItem.labelIssueDate'),
      refNo: this.$t('tracking.trackingList.shipmentListItem.refNo'),
      origin: this.$t('tracking.trackingList.shipmentListItem.origin'),
      destination: this.$t('tracking.trackingList.shipmentListItem.destination'),
      duplicateShipment: this.$t('tracking.trackingList.shipmentListItem.duplicateShipment'),
      exportLabel: this.$t('tracking.trackingList.shipmentListItem.exportLabel'),
      expressCreatedAt: this.$t('tracking.trackingList.shipmentListItem.expressCreatedAt'),
    };
  }
  get isExpress() {
    if (this.trackingOrderType == 'express') {
      return true;
    } else {
      return false;
    }
  }
  get isShipment() {
    if (this.trackingOrderType == 'shipment') {
      return true;
    } else {
      return false;
    }
  }

  get getShipmentListItemState() {
    if (this.trackingOrderType == 'shipment') {
      return TrackingHelpers.determineShipmentState(this.shipment);
    } else {
      return TrackingHelpers.determineExpressState(
        this.shipment.items.map((item: { itemTrackingNumber: string; itemState: string }) => item.itemState),
      );
    }
  }
  get getShipmentOriginDistrict() {
    return TrackingHelpers.getAddressDistrictShorten(this.shipment.origin);
  }
  get getShipmentDestinationDistrict() {
    return this.shipment.isSelfPickUp
      ? TrackingHelpers.getPickUpStoreShorten(this.shipment.pickUpStore)
      : TrackingHelpers.getAddressDistrictShorten(this.shipment.destination);
  }

  async showShipmentDetail() {
    if (this.isShipment) {
      await this.$router.push({
        name: 'TrackingDetail',
        params: { shipmentId: this.shipment.id as string },
      });
    } else {
      await this.$router.push({
        name: 'TrackingDetail',
        params: { shipmentId: this.shipment.id as string },
      });
    }
  }

  displayItemTrackingNumbers(items: any[]): string {
    return Array.isArray(items)
      ? items?.reduce((trackingNumbersString, item) => {
        return trackingNumbersString.concat(item.itemTrackingNumber ?? '', '\n');
      }, '') : ''
    ;
  }

  duplicateShipment() {
    TrackingHelpers.duplicateShipmentRecord(this.shipment.id);
  }

  async exportLabel() {
    //testing: https://staging.aigniter.com/integrated_sales/create_order_receipt/013e81c5-4c6b-44b7-a85e-746f9fed6625

    const url = await fetchLabelUrl(this.shipment.id, this.isExpress ? 'express' : 'otherCourier');
    const ROOT = this.$root.$children[0] as App;
    if (url) {
      ROOT.showPrompt({
        msg: this.$t('tracking.trackingExport.exportLabel') as string,
        acceptBlt: this.$t('tracking.trackingExport.export') as string,
        listener: this,
        execution: () => {
          saveFileTODevice(url);
        },
      });
    } else {
      this.$store.commit('showConnectionError');
    }
  }
}
