var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mdc-form-field " }, [
    _c("div", { ref: _vm.reference, staticClass: "mdc-radio" }, [
      _c("input", {
        staticClass: "mdc-radio__native-control",
        attrs: { type: "radio", name: _vm.name, disabled: _vm.isDisabled },
        domProps: { value: _vm.value, checked: this.default },
        on: { change: _vm.emitToParent }
      }),
      _vm._m(0),
      _c("div", { staticClass: "mdc-radio__ripple" })
    ]),
    _c(
      "label",
      {
        staticClass: "radio-label",
        style: {
          fontSize: _vm.font || "min(1rem, 4vw)",
          color: _vm.isDisabled
            ? _vm.theme.disabledColor
            : _vm.theme.mainThemeColor
        },
        attrs: { for: "reference" }
      },
      [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mdc-radio__background" }, [
      _c("div", { staticClass: "mdc-radio__outer-circle" }),
      _c("div", { staticClass: "mdc-radio__inner-circle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }