var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shipment__container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTitle,
              expression: "showTitle"
            }
          ],
          staticClass: "title__container"
        },
        [_c("TheTitle", { ref: "theTitle" })],
        1
      ),
      _c("router-view", {
        class: {
          height80: _vm.$store.getters.showTitle,
          height100: !_vm.$store.getters.showTitle
        },
        on: {
          shipment: _vm.createShipment,
          proceed: _vm.proceedShipment,
          confirm: _vm.confirmShipment,
          done: _vm.done,
          undone: _vm.confirmShipment
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }