import i18n from '@/i18n';
import store from '@/store';
import router from '@/router';
import { Alpha2ToName, itemCategories } from '@/assets/constant';
import { fetchShipmentRecordbyId } from '@/utils/fetchDataHelpers';
import { SessionStorageHandler as Storage } from '@/utils/sessionStorageHandler';
import * as TranslationHelpers from '@/utils/translationHelpers';
import DateFormatHelpers from '@/utils/dateFormatHelpers';
import * as constant from '@/assets/constant';
import { ShipmentHelpers } from './shipmentHelpers';
import { snakeCase } from "snake-case";

export class TrackingHelpers {
    public static async resetTrackinglistFilter() {
        await store.commit('tracking/setTrackingRecordOrderType', 'express');
        await store.commit('tracking/setTrackingRecordFilterType', 'inProgress');
        await store.commit('tracking/setTrackingRecordFilterCriteria', 'shipment');
        await store.commit('tracking/setTrackingRecordFilterKeyword', '');
    }

    public static determineShipmentState(shipment: ShipmentListItem): any {
        let state = 'processing';
        if (
            shipment.labelState === 'failed' ||
            shipment.labelState === 'Failed' ||
            shipment.labelState === 'technical_failed' ||
            shipment.labelState === 'Technical Failed' ||
            shipment.labelState === 'reported' ||
            shipment.labelState === 'Reported' ||
            shipment.pickupState === 'request_failed' ||
            shipment.pickupState === 'Request Failed' ||
            shipment.deliveryState === 'lost_by_courier' ||
            shipment.deliveryState === 'Lost By Courier' ||
            shipment.deliveryState === 'exception' ||
            shipment.deliveryState === 'Exception' ||
            shipment.deliveryState === 'expired' ||
            shipment.deliveryState === 'Expired'
        ) {
            state = 'error';
        } else if (shipment.deliveryState === 'delivered' || shipment.deliveryState === 'Delivered') {
            state = 'delivered';
        } else if (shipment.shipmentState === 'cancelled' || shipment.shipmentState === 'Cancelled') {
            state = 'cancelled';
        } else if (
            (shipment.pickupState === 'completed' ||
                shipment.pickupState === 'Completed' ||
                shipment.pickupState === 'not_requested' ||
                shipment.pickupState === 'Not Requested') &&
            (shipment.deliveryState === 'pending' ||
                shipment.deliveryState === 'Pending' ||
                shipment.deliveryState === 'info_received' ||
                shipment.deliveryState === 'Info Received' ||
                shipment.deliveryState === 'In Transit To Customer' ||
                shipment.deliveryState === 'in_transit_to_customer' ||
                shipment.deliveryState === 'out_for_delivery' ||
                shipment.deliveryState === 'Out For Delivery')
        ) {
            state = 'delivering';
        } else if (
            shipment.labelState === 'not_created' ||
            shipment.labelState === 'Not Created' ||
            shipment.labelState === 'pending' ||
            shipment.labelState === 'Pending' ||
            shipment.labelState === 'pending' ||
            shipment.labelState === 'Pending' ||
            shipment.labelState === 'generating' ||
            shipment.labelState === 'Generating'
        ) {
            state = 'labelGenerating';
        } else if (
            shipment.pickupState === 'not_requested' ||
            shipment.pickupState === 'Not Requested' ||
            shipment.pickupState === 'pending_confirmation' ||
            shipment.pickupState === 'Pending Confirmation' ||
            shipment.pickupState === 'pending_drop_off' ||
            shipment.pickupState === 'Dending Drop Off' ||
            shipment.pickupState === 'requested' ||
            shipment.pickupState === 'Requested'
        ) {
            state = 'pickingUp';
        } else {
            state = 'processing';
        }
        return i18n.t('tracking.trackingList.shipmentListItem.shipmentState.' + state);
    }

    public static determineExpressState(states: string[]): any {
        let mark: number = 5

        const stateMark: {[status: string]: number} = {
            confirmed: 1,   
            courierAssigned: 1,
            onTheWayToWarehouse: 2,
            passedToCourier: 2,
            processing: 3,
            arrivedAtWarehouse: 3,
            onTheWayToCustomer: 3,
            onTheWayToPickUpStore: 3,
            arrivedAtPickUpStore: 3,
            arrivedAtThirdPartyPickUpStore: 3,
            sendingToAnotherWarehouse: 3,
            scheduledForRedeliver: 3,
            completed: 4,
        };

        const translatedStates = ['CREATED', 'CONFIRMED', 'PASSEDTOCOURIER', 'PROCESSING', 'COMPLETED'];

        for (const state of states) {
            if (state == 'created') {
                mark = 0;
                break;
            }
            else if (constant.expressOrderListThirdPartyStates.includes(snakeCase(state?? "")?.toUpperCase())) {
                return i18n.t('tracking.expressOrderListThirdPartyStatus');
            }
            else {
                if (state in stateMark && stateMark[state] < mark) {
                    mark = stateMark[state]
                }
            }
        }

        if (mark > 4) {
            mark = 3
        }

        return i18n.te('tracking.expressOrderStatus.' + translatedStates[mark])
                ? i18n.t('tracking.expressOrderStatus.' + translatedStates[mark])
                : i18n.t('tracking.expressOrderStatus.PROCESSING');
        
    }
    public static getAddressDistrictShorten(address: ShipmentListAddress): any {
        let displayText = '';
        const i18nAlpha2ToName = i18n.t('constants.alpha2ToName') as Constant;
        // const i18nAlpha2ToName = Alpha2ToName;
        if (address.areaChi) {
            displayText = address.areaChi;
        } else if (address.city) {
            displayText = address.city;
        } else if (address.state) {
            displayText = address.state;
        } else if (address.country) {
            displayText = address.country;
        } else if (address.countryCodeAlpha2 && address.countryCodeAlpha2 in i18nAlpha2ToName) {
            displayText = i18nAlpha2ToName[address.countryCodeAlpha2] as string;
        } else {
            displayText = i18nAlpha2ToName.HK as string;
        }
        try {
            if (TranslationHelpers.hasChinese(displayText)) {
                return TranslationHelpers.extractChinese(displayText);
            } else {
                return displayText;
            }
        } catch (e) {
            return displayText;
        }
    }

    public static getPickUpStoreShorten(pickUpStore: any) {
        return i18n.locale === 'cant' ? pickUpStore?.areaChi : pickUpStore?.areaEn;
    }
    public static async duplicateShipmentRecord(shipmentId: string) {
        // const trackingOrderType = store.getters["tracking/getTrackingOrderType"];
        // if (trackingOrderType === "shipment") {
        //   if (await fetchShipmentRecordbyId(shipmentId)) {
        //     const copyingShipmentRecord =
        //       store.getters["tracking/getShipmentRecord"];
        //     const currentShipmentItems: Item[] =
        //       store.getters["shipment/getShipmentItems"];

        //     store.commit("shipment/resetShipment");
        //     const itemIds = currentShipmentItems.map(item => item.itemId);
        //     itemIds.forEach(itemId => {
        //       store.commit("shipment/removeShipmentItem", itemId);
        //     });
        //     store.commit(
        //       "shipment/saveShipmentSenderAddress",
        //       copyingShipmentRecord.origin
        //     );
        //     store.commit(
        //       "shipment/saveShipmentReceiverAddress",
        //       copyingShipmentRecord.destination
        //     );
        //     store.commit("shipment/addShipmentItems", copyingShipmentRecord.items);

        //     store.commit(
        //       "shipment/setIsDocument", false
        //     );
        //     store.commit(
        //       "shipment/setIsSubprime", false
        //     );
        //     store.commit(
        //       "shipment/setPaymentMethod", "PAID_BY_MERCHANT"
        //     );
        //     store.commit(
        //       "shipment/setCashOnDeliveryAmount", null
        //     );
        //     store.commit(
        //       "shipment/setOrderNotes", null
        //     );

        //     //TODOs: commit set TaxesDutiesPaidBy
        //     //store.commit('shipment/setTaxesDutiesPaidBy', Storage.loadFromSD(TAXES_DUTIES_PAID_BY));
        //     return router.push({
        //       name: "Shipment"
        //     });
        //   }
        // } else if (trackingOrderType === "express") {
        if (await fetchShipmentRecordbyId(shipmentId)) {
            const copyingShipmentRecord = store.getters['tracking/getExpressRecord'];
            store.commit('shipment/resetShipment');
            // const currentShipmentItems: Item[] =
            //   store.getters["shipment/getShipmentItems"];
            // const itemIds = currentShipmentItems.map(item => item.itemId);
            // itemIds.forEach(itemId => {
            //   store.commit("shipment/removeShipmentItem", itemId);
            // });

            store.commit('shipment/setIsSubprime', true);
            store.commit('shipment/setIsDocument', copyingShipmentRecord.isDocument ? true : false);
            store.commit('shipment/setNoOfItems', 1);
            if (
                copyingShipmentRecord.origin.fullAddress &&
                ShipmentHelpers.validateAreaRank(copyingShipmentRecord.origin.areaRank)
            ) {
                store.commit('shipment/saveShipmentSenderAddress', copyingShipmentRecord.origin);
            }
            if (copyingShipmentRecord.isSelfPickUp) {
                const fetchPickUpStoreResult = await store.dispatch('pickUpStore/fetchPickUpStores');
                if (
                    copyingShipmentRecord.pickUpStore.id &&
                    fetchPickUpStoreResult &&
                    store.getters['pickUpStore/getPickUpStoreById'](copyingShipmentRecord.pickUpStore.id)
                ) {
                    store.commit('shipment/setPickUpStoreId', copyingShipmentRecord.pickUpStore.id);
                }
                store.commit('shipment/setPickUpName', copyingShipmentRecord.destination?.contactName);
                store.commit('shipment/setPickUpPhone', copyingShipmentRecord.destination?.contactPhone);
            } else {
                if (
                    copyingShipmentRecord.destination.fullAddress &&
                    ShipmentHelpers.validateAreaRank(copyingShipmentRecord.destination.areaRank)
                ) {
                    store.commit('shipment/saveShipmentReceiverAddress', copyingShipmentRecord.destination);
                }
            }

            //Only duplicate the latest format item of express order to shipment, and only one item can be set in shipment(old version have multiple)
            if (copyingShipmentRecord.items) {
                for (let i = 0; i < copyingShipmentRecord.items.length; i++) {
                    const item = copyingShipmentRecord.items[i];
                    if (item.isLatestFormat) {
                        store.commit('shipment/setExpressOrderItem', item);
                    }
                }
            }
            store.commit(
                'shipment/setPaymentMethod',
                copyingShipmentRecord.paymentMethod ? copyingShipmentRecord.paymentMethod : 'PAID_BY_MERCHANT',
            );
            if (
                copyingShipmentRecord.paymentMethod === 'CASH_ON_DELIVERY' ||
                copyingShipmentRecord.paymentMethod === 'PAID_BY_MERCHANT_COD'
            ) {
                store.commit('shipment/setCashOnDeliveryAmount', copyingShipmentRecord.cashOnDeliveryAmount);
            } else {
                store.commit('shipment/setCashOnDeliveryAmount', null);
            }
            //TODO: validate Date
            if (copyingShipmentRecord.deliveryDate) {
                store.commit(
                    'shipment/setDeliveryDate',
                    DateFormatHelpers.formattedJSDateStringFromDate(new Date(copyingShipmentRecord.deliveryDate)),
                );
            }
            //TODO: validate Date
            if (copyingShipmentRecord.pickUpDate) {
                store.commit(
                    'shipment/setPickUpDate',
                    DateFormatHelpers.formattedJSDateStringFromDate(new Date(copyingShipmentRecord.pickUpDate)),
                );
            }

            store.commit('shipment/setOrderNotes', copyingShipmentRecord.orderNotes);

            //TODOs: commit set TaxesDutiesPaidBy
            //store.commit('shipment/setTaxesDutiesPaidBy', Storage.loadFromSD(TAXES_DUTIES_PAID_BY));
            return router.push({
                name: 'Shipment',
            });
        }
        //}
        return false;
        //TODOs: error prompt
    }

    // try{
    //     const date = new Date(dateString);
    //     return date.getFullYear() + "-" + date.getMonth()+1 + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    // }catch(error){
    //     return '';
    // }
}
