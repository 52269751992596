










































import { Component, Vue, Prop } from "vue-property-decorator";
import { MDCSwitch } from "@material/switch";
import { MDCRipple } from "@material/ripple";

import MessagePrompt from "@/components/dialogs/MessagePrompt.vue";
import TheMDCRadioButton  from "@/components/mdcComponents/radio/TheMDCRadioButton.vue";

import App from "@/App.vue";

@Component({
  components: {
    MessagePrompt
  }
})
export default class TheMDCSwithch extends Vue {
  @Prop() reference!: string;
  @Prop() label!: string;
  @Prop() height?: string;
  @Prop() width?: string;
  @Prop() event?: string;
  @Prop() isChecked!: boolean;
  @Prop() icon?: string;
  @Prop() fontSize?: string;

  @Prop() promptData?: PromptData;
  @Prop() isDisabled?: boolean;
  // @Prop() promptTitle?: string;
  // @Prop() promptMessage?: string;
  // @Prop() promptHTMLMessage?: string;

  private switch!: MDCSwitch;

  mounted() {
    this.switch = new MDCSwitch(
      this.$refs[this.reference as string] as Element
    );
    if (this.icon) {
      const iconButtonRipple = new MDCRipple(
        this.$refs[this.icon as string] as HTMLElement
      );
      iconButtonRipple.unbounded = true;
    }
  }

  openDialog() {
    if (this.promptData) {
      (this.$root.$children[0] as App).showPrompt(this.promptData);
    }
  }
  setOn() {
    this.switch.checked = true;
  }
  setOff() {
    this.switch.checked = false;
  }
  setValue(v: boolean) {
    this.switch.checked = v;
  }
  getValue() {
    return this.switch.checked;
  }

  emitToParent() {
    if (this.event) {
      this.$emit(this.event, this.getValue());
    }
  }
}
