




























































import { Component, Vue, Prop } from "vue-property-decorator";

// MDC Library
import { MDCRipple } from "@material/ripple";

import TheMDCLinearProgress from "@/components/mdcComponents/progressIndicators/TheMDCLinearProgress.vue";
import TheMDCButtonOutlined from "@/components/mdcComponents/buttons/TheMDCButtonOutlined.vue";

@Component({
  components: {
    TheMDCLinearProgress,
    TheMDCButtonOutlined
  }
})
export default class TheTitle extends Vue {
  get text() {
    return {
      createShipment: this.$t("shipment.theTitle.createShipment"),
      proceed: this.$t("shipment.theTitle.proceed"),
      confirmAndPay: this.$t("shipment.theTitle.confirmAndPay")
    };
  }
  private linearProgress!: TheMDCLinearProgress;
  private shipmentCheckPt = "radio_button_unchecked";
  private proceedCheckPt = "radio_button_unchecked";
  private confirmCheckPt = "radio_button_unchecked";

  mounted() {
    Array.from(document.querySelectorAll("mdc-ripple-surface")).forEach(
      Element => {
        const ripple = new MDCRipple(Element);
        ripple.unbounded = true;
      }
    );
    this.linearProgress = this.$refs["processBar"] as TheMDCLinearProgress;
    this.toCreateShipment();

    // add listener to window size in order to update bar progress size
    window.addEventListener("resize", e => {
      this.screenWidth = Math.min(window.innerWidth, 768);
      this.updateBar();
    });
  }

  private state:
    | "toCreateShipment"
    | "toProceed"
    | "toConfirmation"
    | "done"
    | null = null;
  private screenWidth = Math.min(window.innerWidth, 768);

  // Update process bar progress and corresponding icons
  toCreateShipment() {
    this.state = "toCreateShipment";
    const progress = (70 / this.screenWidth) * 0.8;
    this.linearProgress.setProgress(progress);
    this.shipmentCheckPt = "radio_button_checked";
    this.proceedCheckPt = "radio_button_unchecked";
    this.confirmCheckPt = "radio_button_unchecked";
  }
  toProceed() {
    this.state = "toProceed";
    this.linearProgress.setProgress(0.5);
    this.shipmentCheckPt = "radio_button_checked";
    this.proceedCheckPt = "radio_button_checked";
    this.confirmCheckPt = "radio_button_unchecked";
  }
  toConfirmation() {
    this.state = "toConfirmation";
    const progress = 1 - (70 / this.screenWidth) * 0.8;
    this.linearProgress.setProgress(progress);
    this.shipmentCheckPt = "radio_button_checked";
    this.proceedCheckPt = "radio_button_checked";
    this.confirmCheckPt = "radio_button_checked";
  }
  done() {
    this.state = "done";
    this.linearProgress.setProgress(1);
    this.shipmentCheckPt = "radio_button_checked";
    this.proceedCheckPt = "radio_button_checked";
    this.confirmCheckPt = "radio_button_checked";
  }

  // activatively update bar status
  private updateBar() {
    if (this.state === "toCreateShipment") {
      this.toCreateShipment();
    } else if (this.state === "toProceed") {
      this.toProceed();
    } else if (this.state === "toConfirmation") {
      this.toConfirmation();
    } else {
      this.done();
    }
  }
}
