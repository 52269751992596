var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: _vm.reference,
      staticClass: "mdc-tab-bar tracking-title__container",
      attrs: { role: "tablist" }
    },
    [
      _c("div", { staticClass: "mdc-tab-scroller" }, [
        _c("div", { staticClass: "mdc-tab-scroller__scroll-area" }, [
          _c("div", { staticClass: "mdc-tab-scroller__scroll-content" }, [
            _c(
              "button",
              {
                staticClass: "mdc-tab mdc-tab--active",
                attrs: { role: "tab", "aria-selected": "true", tabindex: "0" },
                on: { click: _vm.toInProgress }
              },
              [
                _c("span", { staticClass: "mdc-tab__content" }, [
                  _c(
                    "span",
                    {
                      staticClass: "mdc-tab__icon material-icons",
                      attrs: { "aria-hidden": "true" }
                    },
                    [_vm._v("local_shipping")]
                  ),
                  _c("span", { staticClass: "mdc-tab__text-label tab-label" }, [
                    _vm._v(_vm._s(_vm.text.confirmedShipment))
                  ])
                ]),
                _vm._m(0),
                _c("span", { staticClass: "mdc-tab__ripple" })
              ]
            ),
            _c(
              "button",
              {
                staticClass: "mdc-tab",
                attrs: { role: "tab", "aria-selected": "false", tabindex: "1" },
                on: { click: _vm.toDelivered }
              },
              [
                _c("span", { staticClass: "mdc-tab__content" }, [
                  _c(
                    "span",
                    {
                      staticClass: "mdc-tab__icon material-icons",
                      attrs: { "aria-hidden": "true" }
                    },
                    [_vm._v("history")]
                  ),
                  _c("span", { staticClass: "mdc-tab__text-label tab-label" }, [
                    _vm._v(_vm._s(_vm.text.completedShipment))
                  ])
                ]),
                _vm._m(1),
                _c("span", { staticClass: "mdc-tab__ripple" })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "mdc-tab-indicator mdc-tab-indicator--active" },
      [
        _c("span", {
          staticClass:
            "mdc-tab-indicator__content mdc-tab-indicator__content--underline"
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mdc-tab-indicator" }, [
      _c("span", {
        staticClass:
          "mdc-tab-indicator__content mdc-tab-indicator__content--underline"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }