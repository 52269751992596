var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shipment-detail-form__container" }, [
    _c("div", { staticClass: "inner-white-sheet__container" }, [
      _c("div", { staticClass: "shipemnt-detail-form__header" }, [
        _c(
          "span",
          [
            _c("TheMDCIconButton", {
              staticClass: "shipment-detail-form__back",
              attrs: {
                reference: "closeTrackingDetail",
                icon: "clear",
                event: "backToShipmentList",
                backgroundColor: "theme.$background-color"
              },
              on: { backToShipmentList: _vm.backToShipmentList }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "grid-container inner-form" }, [
        _c(
          "div",
          {
            staticClass:
              "shipment-detail-form__row grid-item-summary inner-grid-list-container"
          },
          [
            _c("div", { staticClass: "shipment-detail-form__row-label" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.text.shipmentDetail) + "\n        "
              )
            ]),
            _c(
              "div",
              {
                staticClass: "shipment-detail-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.refNo))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(_vm._s(_vm.shipmentRecord.refNo))
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "shipment-detail-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.text.mainTrackingNumber) +
                      "\n          "
                  )
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.shipmentRecord.trackingNumber) +
                      "\n          "
                  )
                ])
              ]
            ),
            _vm._l(_vm.shipmentRecord.items, function(item, index) {
              return _c(
                "div",
                {
                  key: item.itemTrackingNumber,
                  staticClass: "itemTrackingNumber"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "shipment-detail-form__field inner-grid-container"
                    },
                    [
                      _c("div", { staticClass: "label__field left-item" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              index === 0 ? _vm.text.itemTrackingNumber : ""
                            ) +
                            "\n            "
                        )
                      ]),
                      _c("div", { staticClass: "data__field right-item" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.itemTrackingNumber) +
                            "\n            "
                        )
                      ])
                    ]
                  )
                ]
              )
            }),
            _vm.shipmentRecord.pickUpStoreTrackingNumber
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.text.pickUpStoreTrackingNumber) +
                          "\n          "
                      )
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.shipmentRecord.pickUpStoreTrackingNumber) +
                          "\n          "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm.shipmentRecord.externalOrderNumber
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.text.externalOrderNumber) +
                          "\n          "
                      )
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.shipmentRecord.externalOrderNumber) +
                          "\n          "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm.isExpress
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("tracking.trackingDetail.shipmentState")
                          ) +
                          "\n          "
                      )
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$te(
                              "tracking.expressOrderStatus." +
                                _vm.shipmentRecord.shipmentState.toUpperCase()
                            )
                              ? _vm.$t(
                                  "tracking.expressOrderStatus." +
                                    _vm.shipmentRecord.shipmentState.toUpperCase()
                                )
                              : _vm.$t("tracking.expressOrderStatus.ERROR")
                          ) +
                          "\n          "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "shipment-detail-form__field inner-grid-container"
              },
              [
                _vm.isShipment
                  ? _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.text.shipmentConfirmDate) +
                          "\n          "
                      )
                    ])
                  : _vm._e(),
                _vm.isExpress
                  ? _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.text.expressCreatedAt) +
                          "\n          "
                      )
                    ])
                  : _vm._e(),
                _vm.isShipment
                  ? _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.shipmentRecord.labelIssueDateTime
                              ? new Date(
                                  _vm.shipmentRecord.labelIssueDateTime
                                ).toLocaleDateString() +
                                  " " +
                                  new Date(
                                    _vm.shipmentRecord.labelIssueDateTime
                                  ).toLocaleTimeString()
                              : _vm.text.hyphen
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e(),
                _vm.isExpress
                  ? _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            new Date(
                              _vm.shipmentRecord.createdAt
                            ).toLocaleDateString() +
                              " " +
                              new Date(
                                _vm.shipmentRecord.createdAt
                              ).toLocaleTimeString()
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _vm.isShipment
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(_vm._s(_vm.text.isInsured))
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.shipmentRecord.isInsured == true
                              ? _vm.text.yes
                              : _vm.text.no
                          ) +
                          "\n          "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "shipment-detail-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.orderType))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.isExpress
                          ? _vm.text.expressOrder
                          : _vm.text.shipmentOrder
                      ) +
                      "\n            "
                  ),
                  _vm.isDocument ? _c("br") : _vm._e(),
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.isDocument
                          ? "(" + _vm.text.documentDelivery + ")"
                          : ""
                      ) +
                      "\n          "
                  )
                ])
              ]
            ),
            _vm.isDocument
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        _vm._s(_vm.$t("tracking.trackingDetail.deliveryDate"))
                      )
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.deliveryDate ? _vm.deliveryDate : "-") +
                          "\n          "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm.isDocument
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        _vm._s(_vm.$t("tracking.trackingDetail.pickUpDate"))
                      )
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.pickUpDate ? _vm.pickUpDate : "-") +
                          "\n          "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm.isExpress
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        _vm._s(_vm.$t("tracking.trackingDetail.noOfParcels"))
                      )
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.shipmentRecord.noOfItems
                              ? _vm.shipmentRecord.noOfItems
                              : _vm.$t("general.notSpecified")
                          ) +
                          "\n          "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm.isExpress
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(_vm._s(_vm.text.notesToMerchant))
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.shipmentRecord.notesToMerchant
                              ? _vm.shipmentRecord.notesToMerchant
                              : _vm.text.hyphen
                          ) +
                          "\n          "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "top-border" }),
            _c(
              "div",
              {
                staticClass: "shipment-detail-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.shipmentFee))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.text.currency) +
                      " " +
                      _vm._s(_vm.shipmentRecord.totalCharge) +
                      " "
                  )
                ])
              ]
            ),
            _vm.isExpress && !_vm.isDocument
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "tracking.trackingDetail.overweightAdditionalCharge"
                          )
                        )
                      )
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.shipmentRecord.additionalWeightFee
                              ? [
                                  _vm.text.currency,
                                  _vm.shipmentRecord.additionalWeightFee
                                ].join(" ")
                              : "-"
                          ) +
                          "\n          "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm.isExpress
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(_vm._s(_vm.text.paymentMethod))
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(_vm._s(_vm.paymentMethodText))
                    ])
                  ]
                )
              : _vm._e(),
            _vm.isExpress &&
            (this.shipmentRecord.paymentMethod === "CASH_ON_DELIVERY" ||
              this.shipmentRecord.paymentMethod === "PAID_BY_MERCHANT_COD")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(_vm._s(_vm.text.cashOnDeliveryAmount))
                    ]),
                    _c("div", { staticClass: "data__field right-item" }, [
                      _vm._v(
                        _vm._s(_vm.text.currency) +
                          " " +
                          _vm._s(_vm.shipmentRecord.cashOnDeliveryAmount)
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm.isExpress ? _c("div", { staticClass: "top-border" }) : _vm._e(),
            _vm.isExpress
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(_vm._s(_vm.text.recipientCharge))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "data__field right-item",
                        staticStyle: { "font-weight": "bold" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.text.currency) +
                            " " +
                            _vm._s(_vm.recipientCharge)
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm.isExpress
              ? _c(
                  "div",
                  {
                    staticClass:
                      "shipment-detail-form__field inner-grid-container"
                  },
                  [
                    _c("div", { staticClass: "label__field left-item" }, [
                      _vm._v(_vm._s(_vm.text.senderCharge))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "data__field right-item",
                        staticStyle: { "font-weight": "bold" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.text.currency) +
                            " " +
                            _vm._s(_vm.senderCharge)
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "shipment-detail-form__row-label" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.text.originDetail) + "\n        "
              )
            ]),
            _c(
              "div",
              {
                staticClass: "shipment-detail-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.from))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(_vm._s(_vm.shipmentRecord.origin.contactName))
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "shipment-detail-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.contact))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(_vm._s(_vm.shipmentRecord.origin.contactPhone))
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "shipment-detail-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.address) + ":")
                ]),
                _c(
                  "div",
                  { staticClass: "data-field right-item address-data" },
                  [_vm._v(" " + _vm._s(_vm.originAddressString))]
                )
              ]
            ),
            _c("div", { staticClass: "shipment-detail-form__row-label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.text.destinationDetail) +
                  "\n        "
              )
            ]),
            _c(
              "div",
              {
                staticClass: "shipment-detail-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.destination))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.shipmentRecord.destination.contactName) +
                      "\n          "
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "shipment-detail-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(_vm._s(_vm.text.contact))
                ]),
                _c("div", { staticClass: "data__field right-item" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.shipmentRecord.destination.contactPhone) +
                      "\n          "
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "shipment-detail-form__field inner-grid-container"
              },
              [
                _c("div", { staticClass: "label__field left-item" }, [
                  _vm._v(
                    _vm._s(
                      _vm.toPickUpStore
                        ? _vm.$t("pickUpStore.pickUpStore")
                        : _vm.text.address
                    )
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "data-field right-item address-data pre-wrap-text"
                  },
                  [_vm._v(" " + _vm._s(_vm.destinationAddressString))]
                )
              ]
            )
          ],
          2
        ),
        _vm.isShipment
          ? _c(
              "div",
              {
                staticClass:
                  "shipment-detail-form__row grid-item-delivery-detail inner-grid-list-container"
              },
              [
                _c("div", { staticClass: "shipment-detail-form__row-label" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.text.deliveryDetail) +
                      "\n        "
                  )
                ]),
                _vm.shipmentRecord.hasPickup
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "shipment-detail-form__field inner-grid-container"
                      },
                      [
                        _c("div", { staticClass: "label__field left-item" }, [
                          _vm._v(_vm._s(_vm.text.pickupTime))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "data__field right-item address-data"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.shipmentRecord.hasPickup
                                    ? _vm.shipmentRecord.preferredMinTime +
                                        " " +
                                        _vm.text.to +
                                        " " +
                                        _vm.shipmentRecord.preferredMaxTime
                                    : "-"
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm.isShipment
          ? _c(
              "div",
              {
                staticClass:
                  "shipment-detail-form__row grid-item-checkpoint-detail inner-grid-list-container"
              },
              [
                _c("div", { staticClass: "shipment-detail-form__row-label" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.text.checkpointDetail) +
                      "\n        "
                  )
                ]),
                _vm.shipmentRecord.checkpoints.length == 0
                  ? _c("div", [
                      _c("div", { staticClass: "checkpoint-no-record" }, [
                        _vm._v(_vm._s(_vm.text.noCheckpointShown))
                      ])
                    ])
                  : _vm._e(),
                _vm._l(_vm.shipmentRecord.checkpoints, function(
                  checkpoint,
                  index
                ) {
                  return _c("div", { key: checkpoint.id }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "shipment-detail-form__row inner-grid-container"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "label__field left-item shipment-detail-form__row-sub-label"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(index + 1) +
                                "\n            "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "data__field right-item" })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "shipment-detail-form__row inner-grid-container"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "sub-label__field left-item" },
                          [_vm._v(_vm._s(_vm.text.location))]
                        ),
                        _c("div", { staticClass: "data__field right-item" }, [
                          _c("div", [
                            _vm._v(
                              "\n                " +
                                _vm._s(checkpoint.city) +
                                "\n                " +
                                _vm._s(checkpoint.state) +
                                "\n                " +
                                _vm._s(checkpoint.country) +
                                "\n              "
                            )
                          ]),
                          _c("div", [_vm._v(_vm._s(checkpoint.location))])
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "shipment-detail-form__row inner-grid-container"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "sub-label__field left-item" },
                          [_vm._v(_vm._s(_vm.text.message))]
                        ),
                        _c("div", { staticClass: "data__field right-item" }, [
                          _vm._v(_vm._s(checkpoint.message))
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "shipment-detail-form__row inner-grid-container"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "sub-label__field left-item" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.text.checkpointTime) +
                                "\n            "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "data__field right-item" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                new Date(
                                  checkpoint.checkpointTime
                                ).toLocaleDateString() +
                                  " " +
                                  new Date(
                                    checkpoint.checkpointTime
                                  ).toLocaleTimeString()
                              ) +
                              "\n            "
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "top-border" })
                  ])
                })
              ],
              2
            )
          : _vm._e(),
        _vm.isExpress
          ? _c(
              "div",
              {
                staticClass:
                  "shipment-detail-form__row grid-item-checkpoint-detail inner-grid-list-container"
              },
              [
                _c("div", { staticClass: "shipment-detail-form__row-label" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "tracking.trackingDetail.expressOrderTrackingDetail"
                        )
                      ) +
                      "\n        "
                  )
                ]),
                _vm._l(_vm.shipmentRecord.items, function(item) {
                  return _c(
                    "div",
                    { key: item.trackngNumber },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "shipment-detail-form__field inner-grid-container"
                        },
                        [
                          _c("div", { staticClass: "label__field left-item" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.text.itemTrackingNumber) +
                                "\n            "
                            )
                          ]),
                          _c("div", { staticClass: "data__field right-item" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.itemTrackingNumber) +
                                "\n            "
                            )
                          ])
                        ]
                      ),
                      item.trackings.length == 0
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "shipment-detail-form__field inner-grid-container"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "sub-label__field left-item" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "tracking.trackingDetail.itemTrackingRecord"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data__field right-item tracking-record-detail"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("general.noRecordFound")
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._l(item.trackings, function(tracking, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "shipment-detail-form__row inner-grid-container"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "label__field left-item shipment-detail-form__row-sub-label"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "tracking.trackingDetail.itemTrackingRecord"
                                        ) +
                                          (index + 1)
                                      ) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c("div", {
                                staticClass: "data__field right-item"
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "shipment-detail-form__row inner-grid-container"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "sub-label__field left-item" },
                                [_vm._v(_vm._s(_vm.text.checkpointTime))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data__field right-item tracking-record-detail"
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.formatTimeString(
                                            tracking.createdAt
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "shipment-detail-form__row inner-grid-container"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "sub-label__field left-item" },
                                [_vm._v(_vm._s(_vm.text.message))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data__field right-item tracking-record-detail"
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          tracking.status
                                            ? _vm.$t(
                                                "tracking.expressOrderStatus." +
                                                  tracking.status.toUpperCase()
                                              )
                                            : "-"
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "shipment-detail-form__row inner-grid-container"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "sub-label__field left-item" },
                                [_vm._v(_vm._s(_vm.text.remarks))]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "data__field right-item tracking-record-detail"
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          tracking.remarks
                                            ? tracking.remarks
                                            : "-"
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        ])
                      }),
                      _c("br")
                    ],
                    2
                  )
                })
              ],
              2
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "shipment-detail-form__row grid-item-items-detail inner-grid-list-container"
          },
          [
            _c("div", { staticClass: "shipment-detail-form__row-label" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.text.itemDetail) + "\n        "
              )
            ]),
            _c(
              "div",
              { staticClass: "shipment-items-container" },
              _vm._l(_vm.shipmentRecord.items, function(item, index) {
                return _c("div", { key: item.id }, [
                  !item.isLatestFormat
                    ? _c("div", { staticClass: "shipment-item-container" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-item-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "label__field left-item" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      item.description
                                        ? (index + 1).toString() +
                                            ". " +
                                            item.description
                                        : _vm.text.hyphen
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item-value__field data__field right-item"
                              },
                              [_vm._v(" x " + _vm._s(item.quantity) + " ")]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-item-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "sub-label__field left-item" },
                              [_vm._v(_vm._s(_vm.text.category))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item-value__field data__field right-item"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item.category || item.oldCategory) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-item-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "sub-label__field left-item" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.text.declaredCustomsValue) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item-value__field data__field right-item"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.shipmentRecord.currency) +
                                    " " +
                                    _vm._s(item.declaredCustomsValue) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-item-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "sub-label__field left-item" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.text.dimension) +
                                    "\n                  "
                                ),
                                _c("p", { staticClass: "subtext" }, [
                                  _vm._v(_vm._s(_vm.text.lwh))
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item-value__field data__field right-item"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item.length) +
                                    _vm._s(_vm.text.multiplySign) +
                                    _vm._s(item.width) +
                                    _vm._s(_vm.text.multiplySign) +
                                    _vm._s(item.height) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _c("div", { staticClass: "top-border" })
                      ])
                    : _c("div", { staticClass: "shipment-item-container" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-item-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "label__field left-item" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      [
                                        (index + 1).toString(),
                                        ". ",
                                        item.categoryOptionId
                                          ? _vm.$i18n.locale === "cant"
                                            ? item.categoryDescriptionChi
                                            : item.categoryDescriptionEn
                                          : item.category ||
                                            _vm.$t("itemCategories.other")
                                      ].join("")
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item-value__field data__field right-item"
                              },
                              [
                                _c("TheMDCButtonShapedRaised", {
                                  ref: "exportLabel",
                                  refInFor: true,
                                  staticClass: "mdc-card__action-button",
                                  attrs: {
                                    label: _vm.text.exportLabel,
                                    reference: "exportLabel",
                                    event: "exportLabel",
                                    trailIcon: "file_copy",
                                    isDisabled:
                                      _vm.trackingOrderType === "shipment" &&
                                      !_vm.shipmentRecord.labelUrl,
                                    height: "30px",
                                    width: "60%",
                                    "font-size": "12px"
                                  },
                                  on: {
                                    exportLabel: function($event) {
                                      return _vm.exportLabel(item.id)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-item-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "sub-label__field left-item" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "tracking.trackingDetail.itemTrackingNumber"
                                      )
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item-value__field data__field right-item"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item.itemTrackingNumber) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        item.itemExternalNumber
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "shipment-detail-form__field inner-grid-container"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "sub-label__field left-item" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.text.externalOrderNumber) +
                                        "\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "item-value__field data__field right-item"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.itemExternalNumber) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-item-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "sub-label__field left-item" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "tracking.trackingDetail.dimension"
                                      )
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item-value__field data__field right-item"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$i18n.locale === "cant"
                                        ? item.dimensionDescriptionChi
                                        : item.dimensionDescriptionEn
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-item-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "sub-label__field left-item" },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.adjustedWeight
                                      ? [
                                          "(",
                                          _vm.$t(
                                            "tracking.trackingDetail.weight"
                                          ),
                                          ")"
                                        ].join("")
                                      : _vm.$t("tracking.trackingDetail.weight")
                                  ) + "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-item-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "sub-label__field left-item" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "tracking.trackingDetail.adjustedWeight"
                                      )
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item-value__field data__field right-item"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      item.adjustedWeight
                                        ? [
                                            item.adjustedWeight,
                                            _vm.$t("tracking.trackingDetail.kg")
                                          ].join("")
                                        : "-"
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-item-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "sub-label__field left-item" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "tracking.trackingDetail.adjustedDimension"
                                      )
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item-value__field data__field right-item"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.adjustedDimension(item)
                                        ? _vm.adjustedDimension(item)
                                        : "-"
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-item-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "sub-label__field left-item" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "tracking.trackingDetail.extraChargeFactor"
                                      )
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item-value__field data__field right-item"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.extraChargeFactor(item)) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm.isDocument
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "shipment-detail-form__field inner-grid-item-container"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "sub-label__field left-item" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "tracking.trackingDetail.temperature"
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "item-value__field data__field right-item"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$i18n.locale === "cant"
                                            ? item.temperatureDescriptionChi
                                            : item.temperatureDescriptionEn
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "sub-label__field left-item" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "tracking.trackingDetail.shipmentState"
                                      )
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "data__field right-item" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$te(
                                        "tracking.expressOrderStatus." +
                                          item.itemState.toUpperCase()
                                      )
                                        ? _vm.$t(
                                            "tracking.expressOrderStatus." +
                                              item.itemState.toUpperCase()
                                          )
                                        : _vm.$t(
                                            "tracking.expressOrderStatus.ERROR"
                                          )
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "shipment-detail-form__field inner-grid-item-container"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "sub-label__field left-item" },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "tracking.trackingDetail.orderNotes"
                                      )
                                    ) +
                                    "\n                "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item-value__field data__field right-item"
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item.itemNotes) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        ),
                        _c("div", { staticClass: "top-border" })
                      ])
                ])
              }),
              0
            ),
            _vm.isDocument &&
            (!_vm.shipmentRecord.items || _vm.shipmentRecord.items.length == 0)
              ? _c("div", { staticClass: "item-is-document" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.text.documentDelivery +
                          _vm.text.coma +
                          _vm.text.itemInfoNotRequired
                      ) +
                      "\n        "
                  )
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          { staticClass: "shipment-detail-form__row grid-item-footer" },
          [
            _c(
              "div",
              { staticClass: "shipment-detail-button-row" },
              [
                _c("TheMDCButtonShapedRaised", {
                  ref: "exportLabel",
                  staticClass: "mdc-card__action-button",
                  attrs: {
                    label: _vm.text.exportAllLabel,
                    reference: "exportLabel",
                    event: "exportLabel",
                    trailIcon: "file_copy",
                    isDisabled:
                      _vm.trackingOrderType === "shipment" &&
                      !_vm.shipmentRecord.labelUrl,
                    height: "40px",
                    width: "100%"
                  },
                  on: { exportLabel: _vm.exportLabel }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "footer",
                staticStyle: { color: "lightgrey", "text-align": "right" }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.thisDate.toLocaleDateString() +
                        " " +
                        _vm.thisDate.toLocaleTimeString()
                    ) +
                    "\n        "
                )
              ]
            )
          ]
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "shipment-detail-bottom-back-row" },
      [
        _c("TheMDCButtonShaped", {
          ref: "return",
          attrs: {
            reference: "return",
            type: "button",
            label: _vm.text.return,
            event: "backToShipmentList",
            height: "40px",
            width: "100%",
            startIcon: "keyboard_arrow_left"
          },
          on: { backToShipmentList: _vm.backToShipmentList }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }