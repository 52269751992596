exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".button {\n  color: rgba(136, 136, 191, 0.5);\n}\n.mdc-tab .mdc-tab__text-label,\n.mdc-tab .mdc-tab__icon {\n  color: rgba(136, 136, 191, 0.5);\n}\n.mdc-tab--active .mdc-tab__text-label,\n.mdc-tab--active .mdc-tab__icon {\n  color: #ff4124;\n}", ""]);

// exports
exports.locals = {
	"mainThemeColor": "#ff4124",
	"mainThemeVariantColor": "white",
	"backgroundColor": "#fcfcfc",
	"darkerBackgroundColor": "#f0f0f0",
	"generalTextColor": "rgba(52, 62, 87, 0.3)",
	"greyTextColor": "rgba(0, 0, 0, 0.6)",
	"disabledColor": "#d3d3d3"
};