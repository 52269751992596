



















































































import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import TheMDCTextFieldOutlined from "@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue";
import TheMDCIconButton from "@/components/mdcComponents/buttons/TheMDCIconButton.vue";
import TheMDCButtonOutlined from "@/components/mdcComponents/buttons/TheMDCButtonOutlined.vue";
import TheMDCSelectOutlined from "@/components/mdcComponents/selects/TheMDCSelectOutlined.vue";
import ShipmentRecordCard from "@/views/tracking/ShipmentRecordCard.vue";
import { TrackingHelpers } from "@/utils/trackingHelpers";

@Component({
  components: {
    ShipmentRecordCard,
    TheMDCTextFieldOutlined,
    TheMDCIconButton,
    TheMDCButtonOutlined,
    TheMDCSelectOutlined
  }
})
/**
 * Try a different approach to do state management
 * added a new attribut this.state
 * see if this is better
 */
export default class Tracking extends Vue {
  // private state: "couriers" | "courier" | "canProceed" = "couriers";
  private extendedCourierId: string | null = null;
  private trackingRecordFilterHelpText: any = "";


  // @Ref("trackingOrderTypeSelect") orderTypeComponent!: TheMDCSelectOutlined;
  @Ref("trackingRecordFilterSelect") filterComponent!: TheMDCSelectOutlined;
  @Ref("trackingRecordFilterInput") keywordComponent!: TheMDCTextFieldOutlined;
  
  get trackingOrderType(): TrackingOrderType {
    // return "shipment";
    return this.$store.getters['tracking/getTrackingOrderType'];
  }
  get text() {
    return {
      //filter
      trackingRecordFilterInput: this.$t(
        "tracking.trackingList.trackingRecordFilterInput"
      ),
      trackingRecordFilterPlaceholder: this.$t(
        "tracking.trackingList.trackingRecordFilterPlaceholder"
      ),
      noRecordFound: this.$t("general.noRecordFound"),
      filterCriteria: this.$t(
        "tracking.trackingList.trackingRecordFilterSelect"
      ),
      filterCriteriaOrigin: this.$t(
        "tracking.trackingList.shipmentFilterCriteria.origin"
      ),
      filterCriteriaDestination: this.$t(
        "tracking.trackingList.shipmentFilterCriteria.destination"
      ),
      filterCriteriaItem: this.$t(
        "tracking.trackingList.shipmentFilterCriteria.item"
      ),
      filterCriteriaShipmentInfo: this.$t(
        "tracking.trackingList.shipmentFilterCriteria.shipmentInfo"
      ),
      filterOriginPlaceholder: this.$t(
        "tracking.trackingList.shipmentFilterCriteria.originPlaceholder"
      ),
      filterDestinationPlaceholder: this.$t(
        "tracking.trackingList.shipmentFilterCriteria.destinationPlaceholder"
      ),
      filterItemPlaceholder: this.$t(
        "tracking.trackingList.shipmentFilterCriteria.itemPlaceholder"
      ),
      filterShipmentInfoPlaceholder: this.$t(
        "tracking.trackingList.shipmentFilterCriteria.shipmentInfoPlaceholder"
      ),
      shipmentOrder: this.$t(
        "tracking.trackingList.trackingOrderType.shipmentOrder"
      ),
      expressOrder: this.$t(
        "tracking.trackingList.trackingOrderType.expressOrder"
      ),
      trackingOrderTypeSelect: this.$t(
        "tracking.trackingList.trackingOrderTypeSelect"
      )
    };
  }

  private searchString: string | null = "";
  mounted() {
    this.$store.commit("showTopBar");
    this.$store.commit("showNavBar");
    this.$store.commit("showTrackingTitle");
    // this.filterCriteriaChange("shipment");
    this.fillingTrackingRecordFilter();
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit("hideLoading");
    });
  }

  private fillingTrackingRecordFilter(){
    const orderType = this.$store.getters["tracking/getTrackingOrderType"];
    const criteria = this.$store.getters["tracking/getTrackingCriteria"];
    const keyword = this.$store.getters["tracking/getTrackingKeyword"];

    // this.orderTypeComponent.setSelectedValue(orderType);
    this.filterComponent.setSelectedValue(criteria);
    this.keywordComponent.setValue(keyword);
  
  }

  get getTrackingRecordType() {
    return this.$store.getters["tracking/getTrackingRecordType"];
  }

  private getTrackingRecordFilterInput() {
    return (this.$refs
      .trackingRecordFilterInput as TheMDCTextFieldOutlined).getValue();
  }
  private getFilterCriteriaSelectedValue() {
    return (this.$refs
      .trackingRecordFilterSelect as TheMDCSelectOutlined).getSelectedValue();
  }
  private getTrackingRecordTypeSelectedValue() {
    return (this.$refs
      .trackingOrderTypeSelect as TheMDCSelectOutlined).getSelectedValue();
  }

  private filterCriteriaChange(
    newFilterCriteria: "shipment" | "origin" | "destination" | "item"
  ) {
    this.$store.commit(
      "tracking/setTrackingRecordFilterCriteria",
      newFilterCriteria
    );
    if (newFilterCriteria == "shipment") {
      this.trackingRecordFilterHelpText = this.text.filterShipmentInfoPlaceholder;
    } else if (newFilterCriteria == "origin") {
      this.trackingRecordFilterHelpText = this.text.filterOriginPlaceholder;
    } else if (newFilterCriteria == "destination") {
      this.trackingRecordFilterHelpText = this.text.filterDestinationPlaceholder;
    } else if (newFilterCriteria == "item") {
      this.trackingRecordFilterHelpText = this.text.filterItemPlaceholder;
    }
  }

  get getTrackingList(): ShipmentListItem[] | ExpressIntro[] {
    // return this.$store.getters["tracking/getShipmentList"];
    if(this.trackingOrderType == 'shipment'){
      return this.$store.getters['tracking/getShipmentList'];
    }else{
      return this.$store.getters['tracking/getExpressIntros'];
    }
  }

  private filterInputChange(newFilterInput: string) {
    this.$store.commit(
      "tracking/setTrackingRecordFilterKeyword",
      newFilterInput ?? ""
    );
  }

  // private async trackingOrderTypeSelectChange(newOrderType: 'shipment' | 'express'){
  //   await this.$store.commit('tracking/setTrackingRecordOrderType',newOrderType);
  //   this.retrieveTrackingList();
  // }

  private async retrieveTrackingList() {
    this.$store.commit("showLoading");
    if(this.trackingOrderType == 'shipment'){
      await this.$store.dispatch("tracking/retrieveShipmentList");
    }else{
      await this.$store.dispatch("tracking/retrieveExpressIntros");
    }
    this.$store.commit("hideLoading");
  }
  get filterCriteriaMenu(): MenuItem[] {
    const shipmentFilterCriteriaMenu: MenuItem[] = [
      {
        key: "shipmentInfo",
        value: "shipment",
        expression: this.text.filterCriteriaShipmentInfo
      },
      {
        key: "origin",
        value: "origin",
        expression: this.text.filterCriteriaOrigin
      },
      {
        key: "destination",
        value: "destination",
        expression: this.text.filterCriteriaDestination
      },
      {
        key: "item",
        value: "item",
        expression: this.text.filterCriteriaItem
      }
    ];
    return shipmentFilterCriteriaMenu;
  }

  get trackingOrderTypeMenu(): MenuItem[] {
    return [
        {
          key: "shipmentOrder",
          value: "shipment",
          expression: this.text.shipmentOrder
        },
        {
          key: "expressOrder",
          value: "express",
          expression: this.text.expressOrder
        }
      ] as MenuItem[]
      ;
  }
}
