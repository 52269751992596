var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: _vm.reference,
      staticClass: "mdc-linear-progress",
      class: { "mdc-linear-progress--indeterminate": _vm.isIndeterminate },
      attrs: {
        role: "progressbar",
        "aria-valuemin": "0",
        "aria-valuemax": "1",
        "aria-valuenow": "0"
      }
    },
    [_vm._m(0), _vm._m(1)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mdc-linear-progress__buffer" }, [
      _c("div", { staticClass: "mdc-linear-progress__buffer-bar" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "mdc-linear-progress__bar mdc-linear-progress__primary-bar"
      },
      [_c("span", { staticClass: "mdc-linear-progress__bar-inner" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }